export const rateUnits = [
    {
        id: 1,
        value: 'LS',
        label: 'LS',
        shouldDisableAmount: false,
        shouldDisableBound: false,
        shouldDisableCurrency: false,
        shouldDisableDuration: false,
        unsupportedFixtureTypes: [],
    },
    {
        id: 2,
        value: 'PMT',
        label: 'PMT',
        shouldDisableAmount: false,
        shouldDisableBound: false,
        shouldDisableCurrency: false,
        shouldDisableDuration: false,
        unsupportedFixtureTypes: [],
    },
    {
        id: 3,
        value: 'RNR',
        label: 'RNR',
        shouldDisableAmount: true,
        shouldDisableBound: true,
        shouldDisableCurrency: true,
        shouldDisableDuration: true,
        unsupportedFixtureTypes: [],
    },
    {
        id: 4,
        value: 'PD',
        label: 'PD',
        shouldDisableAmount: false,
        shouldDisableBound: false,
        shouldDisableCurrency: false,
        shouldDisableDuration: false,
        unsupportedFixtureTypes: [],
    },
    {
        id: 5,
        value: 'PDPR',
        label: 'PDPR',
        shouldDisableAmount: false,
        shouldDisableBound: false,
        shouldDisableCurrency: false,
        shouldDisableDuration: false,
        unsupportedFixtureTypes: [],
    },
    {
        id: 6,
        value: 'O/P',
        label: 'O/P',
        shouldDisableAmount: true,
        shouldDisableBound: true,
        shouldDisableCurrency: true,
        shouldDisableDuration: true,
        unsupportedFixtureTypes: [],
    },
    {
        id: 7,
        value: 'OTHER',
        label: 'OTHER',
        shouldDisableAmount: true,
        shouldDisableBound: true,
        shouldDisableCurrency: true,
        shouldDisableDuration: true,
        unsupportedFixtureTypes: [],
    },
    {
        id: 8,
        value: 'WS',
        label: 'WS',
        shouldDisableAmount: false,
        shouldDisableBound: true,
        shouldDisableCurrency: true,
        shouldDisableDuration: false,
        unsupportedFixtureTypes: [],
    },
    {
        id: 9,
        value: 'BB',
        label: 'BB',
        shouldDisableAmount: false,
        shouldDisableBound: false,
        shouldDisableCurrency: false,
        shouldDisableDuration: false,
        unsupportedFixtureTypes: ['COA', 'VOY'],
    },
    {
        id: 10,
        value: 'PCM',
        label: 'PCM',
        shouldDisableAmount: false,
        shouldDisableBound: false,
        shouldDisableCurrency: false,
        shouldDisableDuration: false,
        unsupportedFixtureTypes: [],
    },
    {
        id: 11,
        value: 'ETS',
        label: 'ETS',
        shouldDisableAmount: false,
        shouldDisableBound: false,
        shouldDisableCurrency: false,
        shouldDisableDuration: false,
        unsupportedFixtureTypes: [],
    },
    {
        id: 12,
        value: 'FuelEU',
        label: 'FuelEU',
        shouldDisableAmount: false,
        shouldDisableBound: false,
        shouldDisableCurrency: false,
        shouldDisableDuration: false,
        unsupportedFixtureTypes: [],
    },
];

export const getAvailableUnits = (type) =>
    rateUnits.filter((unit) => !unit.unsupportedFixtureTypes.includes(type));

export const isUnsupportedUnitForType = (ratePart, type) => {
    return rateUnits.some(
        (unit) =>
            unit.value === ratePart.rateValue?.flatRate?.unit &&
            unit.unsupportedFixtureTypes.includes(type)
    );
};
