import { getDataset } from '../models/Datasets';
import { DEFAULT_CARGO_ORIGINAL_NAME } from '../constants/default.values';
import { brokerManaged, unknown } from '../constants/channelValues';
import {
    balticIndexValue,
    flatRateValue,
    isUnsupportedUnitForType,
} from '_legacy/modules/columns/rate';
import { isUnsupportedBoundForType } from '_legacy/modules/columns/rate/models/RateBounds';

export const updateFreightPortBasisIfRequired = (
    data,
    variables,
    mutationValue,
    relatedFieldName,
    gridType
) => {
    if (
        (!data['freightPortBasis'] ||
            data['freightPortBasis'].shouldBeUpdatedAutomatically) &&
        data[relatedFieldName] &&
        data[relatedFieldName].length > 0
    ) {
        const mutationFieldValue =
            mutationValue && mutationValue.length > 0
                ? mutationValue.filter((p) => p.value === '+').length + 1
                : 0;

        const relatedFieldValue =
            data[relatedFieldName].filter((p) => p.value === '+').length + 1;

        const part =
            relatedFieldName === 'dischargeLocationParts'
                ? {
                      loadCount: mutationFieldValue,
                      dischargeCount: relatedFieldValue,
                  }
                : {
                      loadCount: relatedFieldValue,
                      dischargeCount: mutationFieldValue,
                  };

        const freightPortBasis = {};
        freightPortBasis.shouldBeUpdatedAutomatically = true;
        freightPortBasis.parts =
            mutationFieldValue === 0 || relatedFieldValue === 0 ? null : [part];

        data['freightPortBasis'] = freightPortBasis;
        variables[gridType]['freightPortBasis'] = freightPortBasis;
    }
};

export const updateCargoIfRequired = (
    data,
    variables,
    updatedType,
    gridType
) => {
    const shouldResetCargoIfPeriodType = getDataset(
        data['datasetId']
    ).shouldResetCargoIfPeriodType;

    const cargoParts = data['cargoParts'];
    const isCargoPartsValueDefault =
        cargoParts &&
        cargoParts.length === 1 &&
        cargoParts[0].originalName === DEFAULT_CARGO_ORIGINAL_NAME;

    if (
        shouldResetCargoIfPeriodType &&
        updatedType === 'PERIOD' &&
        isCargoPartsValueDefault
    ) {
        data['cargoParts'] = [];
        variables[gridType]['cargoParts'] = [];
    }
};

export const updateChannel = (data, variables, newBrokers, gridType) => {
    const channelValue =
        newBrokers && newBrokers.length !== 0 ? brokerManaged : unknown;
    data['channel'] = channelValue;
    variables[gridType]['channel'] = channelValue;
};

export const cleanRatesIfRequired = (
    data,
    variables,
    updatedType,
    gridType
) => {
    const rates = data['rates'];

    if (
        rates &&
        rates.rateParts &&
        updatedType !== 'PERIOD' &&
        updatedType !== 'SALE'
    ) {
        const shouldRatesBeCleared = rates.rateParts.some((part) => {
            if (!part.rateValue) {
                return false;
            }

            const isBalticIndexValue = part.rateValue.type === balticIndexValue;
            const isDurationRate = part.rateValue.duration;

            const isUnsupportedUnitOrBound =
                isUnsupportedUnitForType(part, updatedType) ||
                isUnsupportedBoundForType(part, updatedType);

            const isUnsupportedFlatRate =
                part.rateValue.type === flatRateValue &&
                isUnsupportedUnitOrBound;

            return (
                isBalticIndexValue || isDurationRate || isUnsupportedFlatRate
            );
        });

        if (shouldRatesBeCleared) {
            data['rates'] = null;
            variables[gridType]['rates'] = null;
        }
    }
};

export const clearDeliveryTermIfRequried = (
    data,
    variables,
    updatedType,
    gridType
) => {
    if (updatedType === 'VOY' || updatedType === 'COA') {
        data['deliveryTerm'] = null;
        variables[gridType]['deliveryTerm'] = null;
    }
};
