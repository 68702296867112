import { useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import * as layoutActions from '../../../actions/layoutActions';
import { COA, PERIOD, SALE, TCT, UNKNOWN, VOY } from './OrderOrFixtureTypes';
import { DATASET_SPECIALISED } from '_legacy/models/Datasets';

const panelContainerStyle = () => {
    return {
        textAlign: 'left',
        display: 'inline-grid',
        lineHeight: '1.5',
        marginTop: '5px',
        marginLeft: '50px',
        width: '150px',
    };
};

const DefaultTypeItems = (props) => {
    const options = [
        { value: VOY, label: VOY },
        { value: TCT, label: TCT },
        { value: PERIOD, label: PERIOD },
        { value: COA, label: COA },
        { value: UNKNOWN, label: UNKNOWN },
    ];

    if (props.datasetId === DATASET_SPECIALISED) {
        options.push({ value: SALE, label: SALE });
    }

    const [defaultType, setDefaultType] = useState(
        options.find((type) => type.value === props.currentDefaultType)
    );

    const handleOnChangeDefaultTypeFormat = (type) => {
        setDefaultType(type);
        updateLayout(type.value);
        props.api.refreshClientSideRowModel();
    };

    const updateLayout = (type) => {
        props.actions.onDefaultTypeChanged(type);
    };

    return (
        <div style={panelContainerStyle()}>
            <Select
                className="basic-single"
                classNamePrefix="list"
                isSearchable={false}
                options={options}
                onChange={handleOnChangeDefaultTypeFormat}
                value={defaultType}
                openMenuOnFocus={true}
                menuPlacement="auto"
                placeholder="Default type"
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    const props = {
        currentDefaultType:
            state.layouts.selectedLayout.commonSettings.defaultType
                .currentState,
    };

    return props;
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...layoutActions }, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultTypeItems);
