import { commonRowAndCellClassRules } from '_legacy/components/grid/classRules/classRules';
import defaultObjEquals from '_legacy/components/grid/columnDefaults/defaultObjEquals';
import defaultSetFilterParams from '_legacy/components/grid/columnDefaults/defaultSetFilterParams';
import suppressOnEdit from '_legacy/components/grid/columnDefaults/suppressOnEdit';
import NamePropertyComparator from '_legacy/components/grid/comparators/NamePropertyComparator';
import naturalSortComparator from '_legacy/components/grid/comparators/NaturalSortComparator';
import { unassignedOperatorText } from '_legacy/constants/gridText';
import CompanyFormatter from '_legacy/modules/shared/columns/formatters/CompanyFormatter';
import CompanyKeyCreator from '_legacy/modules/shared/columns/keyCreators/CompanyKeyCreator';
import { isEditableRow } from '_legacy/modules/shared/columns/tools/headingTools';
import ThirdPartyOperatorEditor from '_legacy/modules/columns/operator/editors/ThirdPartyOperatorEditor';

export const operatorHeading = {
    colId: 'operator',
    headerName: 'Operator',
    field: 'operator',
    editable: isEditableRow,
    valueFormatter: CompanyFormatter,
    cellEditorFramework: ThirdPartyOperatorEditor,
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: NamePropertyComparator,
    equals: defaultObjEquals,
    keyCreator: (params) =>
        CompanyKeyCreator({ ...params, defaultValue: unassignedOperatorText }),
    enableRowGroup: true,
    filter: 'companiesFilter',
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    width: 120,
};
