import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../actions/userActions';
import { isAdmin } from '../../reducers/userSelectors';
import { Route, Switch } from 'react-router-dom';
import OrdersPage from '../orders/OrdersPage';
import FixturesPage from '../fixtures/FixturesPage';
import AdminPage from '../admin/AdminPage';
import '../ui/Menu.scss';
import '../ui/Toolbar.scss';
import Header from '../Header';
import DataSizeOptions from '../../data/dataSizeOptions';
import ExportOptions, { EmailExportMaxColumnCount } from '../ui/ExportOptions';
import { getReport } from '../../emailExport/reports/SuezmaxDailyReport';
import { generateEmail } from '../../emailExport/EmailService';
import { downloadFile } from '../common/Downloader';
import CompanyFormatter from '_legacy/modules/shared/columns/formatters/CompanyFormatter';
import { MultipleCargoQuantityFormatter } from '_legacy/modules/columns/quantity';
import MultipleCargoFormatter from '../grid/formatters/MultipleCargoFormatter';
import LocationsFormatter from '../grid/formatters/LocationsFormatter';
import { VesselFormatter } from '_legacy/modules/columns/vessel';
import { MultipleRateFormatter } from '_legacy/modules/columns/rate';
import { MultipleDurationFormatter } from '_legacy/modules/columns/duration';
import { modalService } from 'common';
import { AuditHistoryService } from '_legacy/services/AuditHistoryService';
import {
    ORDER_GRID_TYPE,
    FIXTURE_GRID_TYPE,
} from '_legacy/constants/gridTypes';
import MixpanelLogger from '_legacy/diagnostics/logging/mixpanelLogger';
import {
    OrdersPaneId,
    FixturesPaneId,
} from '_legacy/constants/CommonConstants';
import userSettingsApi from '_legacy/api/userSettingsApi';
import { buildEmailReport } from '_legacy/emailExport/EmailReportBuilder';

export class routes extends Component {
    constructor(props) {
        super(props);

        this.subMenuRef = React.createRef();
        this.pageRef = React.createRef();

        this.state = {
            context: 'single',
            selectedOption: this.props.selectedOption || DataSizeOptions[0],
            export: {
                includesConfidential: false,
                orders: [],
                fixtures: [],
            },
            useCondensedView: this.props.useCondensedView,
            groupsChanged: false,
        };

        this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
        this.openExportOptions = this.openExportOptions.bind(this);
        this.handleExport = this.handleExport.bind(this);
        this.handleGroupChange = this.handleGroupChange.bind(this);

        this.RenderOrdersPageWithMenu =
            this.RenderOrdersPageWithMenu.bind(this);
        this.RenderFixturesPageWithMenu =
            this.RenderFixturesPageWithMenu.bind(this);
        this.RenderAdminPageWithMenu = this.RenderAdminPageWithMenu.bind(this);
        this.handleOnToggleCondensedView =
            this.handleOnToggleCondensedView.bind(this);
    }

    openExportOptions() {
        const gridRef = this.pageRef.current.getWrappedInstance();
        const content = (
            <ExportOptions
                onClose={modalService.close}
                onExport={this.handleExport}
                includesNonConfidential={
                    this.state.export.includesNonConfidentialOrders ||
                    this.state.export.includesNonConfidentialFixtures
                }
                includesConfidential={
                    this.state.export.includesConfidentialOrders ||
                    this.state.export.includesConfidentialFixtures
                }
                includesGroupHeadings={
                    this.includesGroupHeadings(this.state.export.orders) ||
                    this.includesGroupHeadings(this.state.export.fixtures)
                }
                shouldShowEmailColumnWarning={
                    gridRef.getExportColumnsNumber() > EmailExportMaxColumnCount
                }
            />
        );

        modalService.open({ content });
    }

    includesGroupHeadings(nodes) {
        return nodes.length > 0 && nodes[0].level > 0;
    }

    handleExport(params) {
        var shouldIncludeRow = (data) => {
            if (params.includesConfidential && data.privacy) {
                return params.exportConfidential;
            } else {
                return true;
            }
        };

        const orders = this.state.export.orders
            .filter((row) => shouldIncludeRow(row.data))
            .map((row) => row.data);
        const fixtures = this.state.export.fixtures
            .filter((row) => shouldIncludeRow(row.data))
            .map((row) => row.data);

        const exportType =
            orders.length !== 0 && fixtures.length !== 0
                ? 'Both'
                : orders.length !== 0
                ? 'Orders'
                : 'Fixtures';
        const quantityItemsToExport = orders.length + fixtures.length;

        if (params.format === 'excel') {
            this.pageRef.current
                .getWrappedInstance()
                .exportToExcel(
                    shouldIncludeRow,
                    params.shouldExportGroupHeadings
                );
            MixpanelLogger.trackExportToExcelEvent(
                exportType,
                quantityItemsToExport,
                params.exportConfidential,
                params.shouldExportGroupHeadings
            );
        } else if (params.format === 'email') {
            let report;
            if (this.props.newThemeEnabled) {
                const reportData = this.pageRef.current
                    .getWrappedInstance()
                    .getEmailReportData(shouldIncludeRow);
                report = buildEmailReport(reportData, params.reportTitle);
            } else {
                const data = { orders, fixtures, title: params.reportTitle };

                const formatters = {
                    charterer: CompanyFormatter,
                    broker: CompanyFormatter,
                    cargoQuantity: (value) => {
                        return MultipleCargoQuantityFormatter(
                            {
                                ...value,
                                context: {
                                    quantityFormat: this.props.quantityFormat,
                                },
                            },
                            true
                        );
                    },
                    cargoParts: MultipleCargoFormatter,
                    loadLocationParts: LocationsFormatter,
                    dischargeLocationParts: LocationsFormatter,
                    vessel: VesselFormatter,
                    rates: MultipleRateFormatter,
                    durationParts: MultipleDurationFormatter,
                };

                report = getReport(data, new Date(), formatters);
            }
            const email = generateEmail(report);
            downloadFile(email);
            MixpanelLogger.trackExportToEmailEvent(
                exportType,
                quantityItemsToExport,
                params.exportConfidential
            );
        }

        this.LogExportHistory(params.format, orders, fixtures);

        modalService.close();
    }

    LogExportHistory(format, orders, fixtures) {
        const isOrdersExport = orders.length !== 0;
        const itemsCount = isOrdersExport ? orders.length : fixtures.length;
        const recordType = isOrdersExport ? ORDER_GRID_TYPE : FIXTURE_GRID_TYPE;
        const auditParams = this.pageRef.current
            .getWrappedInstance()
            .getAuditParams();

        AuditHistoryService.createExportHistory(
            format,
            itemsCount,
            recordType,
            auditParams
        );
    }
    handleOnToggleCondensedView = async () => {
        try {
            this.props.actions.toggleCondensedView();
            await userSettingsApi.toggleCondensedView();
        } catch (error) {
            console.error('Error setting condensed View:', error);
        }
    };
    RenderOrdersPageWithMenu = (props) => {
        return (
            <OrdersPage
                {...props}
                ref={this.pageRef}
                onSelectionChanged={this.handleSelectionChanged}
                exportParams={this.state.export}
                onExportClicked={this.openExportOptions}
                context={this.state.context}
                useCondensedView={this.state.useCondensedView}
                showCondensedViewToggle={true}
                onToggleCondensedView={this.handleOnToggleCondensedView}
                handleGroupChange={this.handleGroupChange}
                isAdmin={this.props.isAdmin}
                singleView
            />
        );
    };

    RenderFixturesPageWithMenu = (props) => {
        return (
            <FixturesPage
                {...props}
                ref={this.pageRef}
                numberOfYears={this.state.selectedOption.value}
                onNumberOfYearsChanged={this.props.actions.dataSizeSet}
                onSelectionChanged={this.handleSelectionChanged}
                exportParams={this.state.export}
                onExportClicked={this.openExportOptions}
                context={this.state.context}
                useCondensedView={this.state.useCondensedView}
                showCondensedViewToggle={true}
                onToggleCondensedView={this.handleOnToggleCondensedView}
                handleGroupChange={this.handleGroupChange}
                isAdmin={this.props.isAdmin}
                singleView
            />
        );
    };

    RenderAdminPageWithMenu = (props) => {
        return (
            <React.Fragment>
                <AdminPage
                    {...props}
                    onGroupsChanged={() => {
                        this.setState({ groupsChanged: true });
                    }}
                />
                <div
                    style={{
                        height: '36px',
                        marginTop: '30px',
                        position: 'fixed',
                        bottom: '0px',
                    }}
                >
                    <Header
                        showAdmin={isAdmin}
                        onTabSelect={(to) => {
                            if (
                                !this.state.groupsChanged ||
                                to.includes('admin')
                            ) {
                                props.history.push(to);
                            } else {
                                window.location.href = to;
                            }
                        }}
                    />
                </div>
            </React.Fragment>
        );
    };

    handleGroupChange = () => {
        this.setState({
            export: {
                orders: [],
                fixtures: [],
            },
        });
    };

    handleSelectionChanged(_export) {
        this.setState({ export: _export });
    }

    componentDidUpdate(prevProps, prevState) {
        const { location } = this.props;
        const previousLocation = prevProps.location;

        if (previousLocation.pathname !== location.pathname) {
            this.setState({
                ...prevState,
                export: {
                    ...prevState.export,
                    orders: [],
                    fixtures: [],
                },
            });
        }
        if (prevProps.useCondensedView !== this.props.useCondensedView) {
            this.setState({
                useCondensedView: this.props.useCondensedView,
            });
        }
    }

    render() {
        const { isAdmin, location } = this.props;

        let paneId = null;

        if (location) {
            if (location.pathname === '/fixtures') {
                paneId = FixturesPaneId;
            } else {
                paneId = OrdersPaneId;
            }
        }

        return (
            <div style={{ height: '100%' }}>
                <div
                    id={paneId}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <div
                        style={{
                            position: 'relative',
                            flexGrow: 1,
                            height: '100%',
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                            }}
                        >
                            <Switch>
                                <Route
                                    path="/orders"
                                    render={this.RenderOrdersPageWithMenu}
                                />
                                <Route
                                    path="/fixtures"
                                    render={this.RenderFixturesPageWithMenu}
                                />
                                {isAdmin && (
                                    <Route
                                        path="/admin"
                                        render={this.RenderAdminPageWithMenu}
                                    />
                                )}
                                <Route
                                    path="/"
                                    render={this.RenderOrdersPageWithMenu}
                                />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAdmin: isAdmin(state),
        newThemeEnabled: state.user.themeSettings.newThemeEnabled,
        useCondensedView: state.user.themeSettings.useCondensedView,
        selectedOption: state.user.dataSize,
        quantityFormat:
            state.layouts.selectedLayout.commonSettings.quantityFormat
                .currentState,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...userActions }, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(routes);
