import Warning from '@mui/icons-material/Warning';
import SvgIcon from '@mui/material/SvgIcon';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    Typography,
} from '@mui/material';
import React from 'react';

export interface FortDialogProps {
    idx: string;
    open: boolean;
    onCancel: () => void;
    title?: string | React.ReactNode;
    Icon?: typeof SvgIcon;
    text: string | React.ReactNode;
    actions: React.ReactNode;
}

const FortDialog: React.FC<FortDialogProps> = ({
    idx,
    open,
    onCancel,
    title = 'Warning',
    Icon = Warning,
    text,
    actions,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            onClick={(e) => e.stopPropagation()}
            aria-labelledby={idx + '_dialog_title'}
            aria-describedby={idx + '_dialog_description'}
        >
            <DialogTitle
                component={Stack}
                direction="row"
                id={idx + '_dialog_title'}
                sx={{ p: 'auto', justifyContent: 'center' }}
            >
                <Icon
                    color="error"
                    sx={{
                        display: 'block',
                        marginY: 'auto',
                        marginRight: '10px',
                    }}
                />
                <Box>
                    <Typography variant="h4">{title}</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id={idx + '_dialog_description'}
                    sx={{ fontWeight: 'bold' }}
                >
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>{actions}</DialogActions>
        </Dialog>
    );
};

export default FortDialog;
