import React, { Component } from 'react';
import FortCellEditor from '_legacy/modules/shared/columns/editors/FortCellEditor';
import PropTypes from 'prop-types';

export default class FreeTextCellEditor extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();

        let value = '';
        if (this.props.charPress) {
            value = this.props.charPress;
        } else if (this.props.value !== undefined) {
            value = this.props.value;
        }

        this.state = {
            value,
            shouldSelect: !this.props.charPress,
        };

        this.maxLength = this.props.maxLength || 255;

        this.handleChange = this.handleChange.bind(this);
        this.focus = this.focus.bind(this);
    }

    afterGuiAttached() {
        this.focus();
    }

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();

            if (this.state.shouldSelect) {
                this.inputRef.current.select();
            }
        }
    }

    getValue() {
        return this.state.value;
    }

    handleChange(event) {
        const inputValue = event.target.value;

        // Enforce max length by checking input size
        if (inputValue.length <= this.maxLength) {
            this.setState({
                value: inputValue,
                shouldSelect: false,
            });
        }
    }

    render() {
        return (
            <FortCellEditor focus={this.focus} {...this.props}>
                <input
                    type="text"
                    ref={this.inputRef}
                    value={this.state.value}
                    onChange={this.handleChange}
                    maxLength={this.maxLength}
                    style={{ width: '100%', boxSizing: 'border-box' }}
                />
            </FortCellEditor>
        );
    }
}

FreeTextCellEditor.defaultProps = {
    maxLength: 255,
};

FreeTextCellEditor.propTypes = {
    value: PropTypes.string,
    charPress: PropTypes.string,
    maxLength: PropTypes.number,
};
