import {
    commonRowAndCellClassRules,
    statusClassRules,
} from '../../components/grid/classRules/classRules';
import excelStyleRules from '../styleRules/ExcelStyleRules';
import { colors, fonts } from '../constants/styles';

export const getGroupHeadingStyleSettings = (node, isLegacyExport) => {
    // default style settings
    const styleSettings = {
        font: {
            name: isLegacyExport ? fonts.default : 'Noto Sans Zawgyi SemiBold',
            family: 4,
            size: 9,
            bold: isLegacyExport,
            color: { argb: colors.black },
        },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: isLegacyExport ? colors.grey3 : colors.white },
        },
        alignment: { vertical: 'middle', horizontal: 'left' },
        rowHeight: 20,
        border: {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        },
    };

    switch (node.level) {
        case 0:
            styleSettings.font.size = 11;
            if (!isLegacyExport) {
                styleSettings.font.color.argb = colors.white;
            }
            styleSettings.fill.fgColor.argb = isLegacyExport
                ? colors.grey1
                : colors.darkblue;
            styleSettings.rowHeight = 24;

            return styleSettings;
        case 1:
            styleSettings.font.size = 10;
            styleSettings.fill.fgColor.argb = isLegacyExport
                ? colors.grey2
                : colors.white;
            styleSettings.rowHeight = 22;

            return styleSettings;
        default:
            return styleSettings;
    }
};

export const getColumnHeaderStyleSettings = (isLegacyExport) => {
    return {
        font: {
            name: isLegacyExport ? fonts.default : 'Calibri',
            size: isLegacyExport ? fonts.defaultSize : 11,
            bold: isLegacyExport,
        },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: colors.grey4 },
        },
        rowHeight: 18.75,
    };
};

export const getDataColumnStyleSettings = (
    colDef,
    node,
    context,
    isLegacyExport
) => {
    const rulesToApply = getStyleRules(colDef, node, context);
    // default style settings
    let styleSettings = {
        font: {
            name: isLegacyExport ? fonts.default : 'Calibri',
            size: isLegacyExport ? fonts.defaultSize : 11,
            bold: isLegacyExport,
        },
        fill: {
            type: 'pattern',
            pattern: 'solid',
        },
        border: isLegacyExport
            ? undefined
            : {
                  top: { style: 'thin', color: { argb: colors.grey6 } },
                  bottom: { style: 'thin', color: { argb: colors.grey6 } },
                  right: { style: 'thin', color: { argb: colors.grey6 } },
                  left: { style: 'thin', color: { argb: colors.grey6 } },
              },
    };

    const rulesObjects = excelStyleRules.filter(
        (style) => rulesToApply.filter((rule) => style.id === rule).length > 0
    );

    for (const rulesObj of rulesObjects) {
        if (rulesObj.font) {
            styleSettings.font = { ...styleSettings.font, ...rulesObj.font };
        }
        if (rulesObj.fill) {
            styleSettings.fill = { ...styleSettings.fill, ...rulesObj.fill };
        }
        if (rulesObj.alignment) {
            styleSettings.alignment = {
                ...styleSettings.alignment,
                ...rulesObj.alignment,
            };
        }
    }

    return styleSettings;
};

export const getHeaderStyleSettings = (row) => {
    row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: colors.white },
    };
    row.font = {
        name: 'Calibri',
        size: 11,
        bold: true,
    };
    return row;
};

export const getStyleRules = (colDef, node, context) => {
    const rulesToApply = [];
    const availableRules =
        colDef.colId === 'status'
            ? statusClassRules
            : commonRowAndCellClassRules;

    for (const [ruleId, rulePredicate] of Object.entries(availableRules)) {
        const shouldApplyRule = rulePredicate({
            colDef: colDef,
            data: node.data,
            context: context,
            value: node.data[`${colDef.field}`],
        });

        if (shouldApplyRule) rulesToApply.push(ruleId);
    }

    return rulesToApply;
};
