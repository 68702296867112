import colors from './colors';

export interface IStyleRule {
    id: string;
    color?: string;
    backgroundColor?: string;
    textDecoration?: string;
}

const emailStyleRules: IStyleRule[] = [
    {
        id: 'grid-row-clarksons-private',
        color: colors.pink3,
        backgroundColor: colors.black,
    },
    {
        id: 'grid-row-market-private',
        backgroundColor: colors.pink2,
    },
    {
        id: 'grid-row-clarksons-public',
        color: colors.pink3,
    },
    {
        id: 'grid-cell-private',
        backgroundColor: colors.pink2,
    },
    {
        id: 'grid-cell-rumoured',
        color: colors.grey5,
    },
    {
        id: 'grid-row-own-program',
        color: colors.lightBlue,
    },
    {
        id: 'grid-row-faded-out',
        color: colors.grey5,
    },
    {
        id: 'grid-row-new',
        color: colors.green,
    },
    {
        id: 'grid-row-converted',
        color: colors.blue,
    },
    {
        id: 'grid-row-deleted',
        textDecoration: 'line-through',
    },
    {
        id: 'grid-cell-excel-green',
        color: colors.white,
        backgroundColor: colors.green,
    },
    {
        id: 'grid-cell-excel-red',
        color: colors.white,
        backgroundColor: colors.burgundy,
    },
    {
        id: 'grid-cell-excel-black',
        color: colors.white,
        backgroundColor: colors.black,
    },
    {
        id: 'grid-row-clarksons-on-subs',
        color: colors.red2,
        backgroundColor: colors.pink4,
    },
    {
        id: 'grid-row-on-subs',
        color: colors.black,
        backgroundColor: colors.pink4,
    },
    {
        id: 'excel-status-clarksons-private',
        color: colors.pink3,
        backgroundColor: colors.black,
    },
    {
        id: 'excel-status-clarksons-public',
        color: colors.pink3,
    },
    {
        id: 'excel-status-market-private',
        backgroundColor: colors.pink2,
    },
    {
        id: 'grid-cell-own-program',
        color: colors.lightBlue,
    },
];

export default emailStyleRules;
