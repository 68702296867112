import { commonRowAndCellClassRules } from '_legacy/components/grid/classRules/classRules';
import defaultSetFilterParams from '_legacy/components/grid/columnDefaults/defaultSetFilterParams';
import naturalSortComparator from '_legacy/components/grid/comparators/NaturalSortComparator';
import { isEditableRow } from '_legacy/modules/shared/columns/tools/headingTools';
import BlanksFilterRenderer from '_legacy/components/grid/renderers/BlanksFilterRenderer';
import TooltipRenderer from '_legacy/components/grid/renderers/TooltipRenderer';
import FreeTextCellEditor from '_legacy/modules/shared/columns/editors/FreeTextCellEditor';
import { unassignedOpportunityText } from '_legacy/constants/gridText';

export const opportunityHeading = {
    colId: 'opportunity',
    headerName: 'Opportunity',
    field: 'opportunity',
    editable: isEditableRow,
    type: ['privatable'],
    keyCreator: ({ value }) => value ?? unassignedOpportunityText,
    cellEditorFramework: FreeTextCellEditor,
    cellEditorParams: {
        maxLength: 50,
    },
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    cellRenderer: TooltipRenderer,
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    width: 120,
    suppressPaste: false,
};
