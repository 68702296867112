import _ from 'lodash';
import {
    LayoutGridColumn,
    LayoutGridConfigMap,
    LayoutGridType,
    SavedLayoutModel,
    SelectedLayoutModel,
} from './models';
import { LayoutsState } from './models';
import layoutsInitialState from './initial';
import {
    getDefaultFixturesRowGroupsSorting,
    getDefaultOrdersRowGroupsSorting,
} from '_legacy/services/RowGroupsSortingService';
import {
    getDefaultFixturesRowGroups,
    getDefaultOrdersRowGroups,
} from '_legacy/services/RowGroupsService';
import {
    fetchShowDeletedFixtures,
    fetchShowDeletedOrders,
} from '_legacy/localStorage';
import { ensureAreasGroupingByDataset } from '_legacy/models/Areas';
import { LayoutsApi } from 'api';
import { getDataset } from '_legacy/models/Datasets';

export module LayoutGridUpdateUtils {
    export function revertLayout(layout: SelectedLayoutModel) {
        layout.areThereAnyUnsavedLayoutChanges = false;
        layout.commonSettings.defaultType.currentState =
            layout.commonSettings.defaultType.initialState;
        layout.commonSettings.directionLogic.currentState =
            layout.commonSettings.directionLogic.initialState;
        layout.commonSettings.quantityFormat.currentState =
            layout.commonSettings.quantityFormat.initialState;

        layout.fixtures.currentOptions = _.cloneDeep(
            layout.fixtures.initialOptions
        );
        layout.fixtures.hasRecentChanges = false;
        layout.fixtures.hasDifferencies = {};

        layout.orders.currentOptions = _.cloneDeep(
            layout.orders.initialOptions
        );
        layout.orders.hasRecentChanges = false;
        layout.orders.hasDifferencies = {};
    }

    export function setDefaultGroupping(
        model: SelectedLayoutModel,
        gridType: LayoutGridType,
        datasetId: number | null
    ): void {
        switch (gridType) {
            case 'fixtures':
                processFixturesChanges(model, {
                    rowGroups: getDefaultFixturesRowGroups(datasetId),
                    rowGroupsSorting: getDefaultFixturesRowGroupsSorting(
                        datasetId,
                        model.commonSettings.directionLogic.initialState
                    ),
                });
                break;
            case 'orders':
                processOrdersChanges(model, {
                    rowGroups: getDefaultOrdersRowGroups(datasetId),
                    rowGroupsSorting: getDefaultOrdersRowGroupsSorting(
                        datasetId,
                        model.commonSettings.directionLogic.initialState
                    ),
                });
                break;
        }
    }

    /**
     *
     * @param sbj
     * @param input
     * @param prop
     * @returns undefined if setting was not changed, true if setting was changed and has diff, false otherwise
     */
    function updateSettingIfChanged<
        P extends keyof C,
        C extends Record<string, any>
    >(
        sbj: {
            currentOptions: C;
            initialOptions: C;
            hasRecentChanges: boolean;
            hasDifferencies: { [K in keyof C]?: boolean };
        },
        input: Partial<C>,
        prop: P
    ): void {
        if (
            input[prop] !== undefined &&
            !_.isEqual(sbj.currentOptions[prop], input[prop])
        ) {
            sbj.currentOptions[prop] = input[prop]!!;
            sbj.hasRecentChanges = true;
            sbj.hasDifferencies[prop] = !_.isEqual(
                sbj.currentOptions[prop],
                sbj.initialOptions[prop]
            );
        }
    }

    export function processFixturesChanges(
        current: SelectedLayoutModel,
        input: Partial<LayoutGridConfigMap['fixtures']>
    ): void {
        const currentOptions = current.fixtures;

        updateSettingIfChanged(currentOptions, input, 'columnOptions');
        updateSettingIfChanged(currentOptions, input, 'filterOptions');
        updateSettingIfChanged(currentOptions, input, 'rowGroups');
        updateSettingIfChanged(currentOptions, input, 'rowGroupsSorting');
        updateSettingIfChanged(
            currentOptions,
            input,
            'shouldOnlyDisplayRumouredFixtures'
        );
        updateSettingIfChanged(
            currentOptions,
            input,
            'shouldShowDeletedFixtures'
        );
        updateSettingIfChanged(currentOptions, input, 'vesselOptions');
    }

    export function processOrdersChanges(
        current: SelectedLayoutModel,
        input: Partial<LayoutGridConfigMap['orders']>
    ): void {
        const currentOptions = current.orders;

        updateSettingIfChanged(currentOptions, input, 'columnOptions');
        updateSettingIfChanged(currentOptions, input, 'filterOptions');
        updateSettingIfChanged(currentOptions, input, 'rowGroups');
        updateSettingIfChanged(currentOptions, input, 'rowGroupsSorting');
        updateSettingIfChanged(
            currentOptions,
            input,
            'shouldHighlightNewOrders'
        );
        updateSettingIfChanged(
            currentOptions,
            input,
            'shouldShowConvertedOrders'
        );
        updateSettingIfChanged(
            currentOptions,
            input,
            'shouldOnlyDisplayRumouredOrders'
        );
        updateSettingIfChanged(
            currentOptions,
            input,
            'shouldShowDeletedOrders'
        );
    }

    export function processChanges<GT extends LayoutGridType>(
        gridType: GT,
        current: SelectedLayoutModel,
        input: Partial<LayoutGridConfigMap[GT]>
    ) {
        switch (gridType) {
            case 'fixtures':
                processFixturesChanges(current, input);
                break;
            case 'orders':
                processOrdersChanges(current, input);
                break;
        }
    }
}

export module LayoutSerializer {
    export function serializeLayoutData(
        data: SelectedLayoutModel
    ): Pick<SavedLayoutModel, 'fixtures' | 'orders' | 'commonSettings'> {
        const currentFixtures = data.fixtures.currentOptions;
        const fixtures = {
            filters: { ...currentFixtures.filterOptions },
            columnState: [...currentFixtures.columnOptions],
            vesselOptions: currentFixtures.vesselOptions,
            rowGroupsSorting: currentFixtures.rowGroupsSorting,
            rowGroups: currentFixtures.rowGroups,
            shouldOnlyDisplayRumouredFixtures:
                currentFixtures.shouldOnlyDisplayRumouredFixtures,
            shouldShowDeletedFixtures:
                currentFixtures.shouldShowDeletedFixtures,
            collapsedRowGroups: data.fixtures.collapsedRowGroups,
        };

        const currentOrders = data.orders.currentOptions;
        const orders = {
            filters: { ...currentOrders.filterOptions },
            columnState: [...currentOrders.columnOptions],
            shouldHighlightNewOrders: currentOrders.shouldHighlightNewOrders,
            shouldShowConvertedOrders: currentOrders.shouldShowConvertedOrders,
            shouldOnlyDisplayRumouredOrders:
                currentOrders.shouldOnlyDisplayRumouredOrders,
            shouldShowDeletedOrders: currentOrders.shouldShowDeletedOrders,
            rowGroupsSorting: currentOrders.rowGroupsSorting,
            rowGroups: currentOrders.rowGroups,
            collapsedRowGroups: data.orders.collapsedRowGroups,
        };

        const commonSettings = {
            directionLogic: data.commonSettings.directionLogic.currentState,
            quantityFormat: data.commonSettings.quantityFormat.currentState,
            defaultType: data.commonSettings.defaultType.currentState,
        };

        return {
            fixtures: JSON.stringify(fixtures),
            orders: JSON.stringify(orders),
            commonSettings,
        };
    }

    function normalizeOrdersOptions(
        orders: any,
        datasetId: number | null,
        directionLogic: string
    ): LayoutGridConfigMap['orders'] {
        const { initialOptions } = layoutsInitialState.selectedLayout.orders;

        let rowGroups = orders.rowGroups;
        if (!rowGroups || rowGroups.length === 0) {
            rowGroups = getDefaultOrdersRowGroups(datasetId);
            rowGroups.forEach((rg) => {
                rg.checked = false;
            });
        }
        let rowGroupsSorting = orders.rowGroupsSorting;
        if (
            !rowGroupsSorting ||
            rowGroupsSorting.length === 0 ||
            !ensureAreasGroupingByDataset(datasetId, rowGroupsSorting)
        ) {
            rowGroupsSorting = getDefaultOrdersRowGroupsSorting(
                datasetId,
                directionLogic
            );
        }

        return {
            filterOptions: orders.filters ?? {},
            columnOptions: orders.columnState ?? [],
            shouldHighlightNewOrders:
                orders.shouldHighlightNewOrders ||
                initialOptions.shouldHighlightNewOrders,
            shouldShowConvertedOrders:
                orders.shouldShowConvertedOrders ||
                initialOptions.shouldShowConvertedOrders,
            shouldOnlyDisplayRumouredOrders:
                orders.shouldOnlyDisplayRumouredOrders ||
                initialOptions.shouldOnlyDisplayRumouredOrders,
            shouldShowDeletedOrders:
                (orders.shouldShowDeletedOrders === undefined
                    ? fetchShowDeletedOrders()
                    : orders.shouldShowDeletedOrders) ||
                initialOptions.shouldShowDeletedOrders,
            rowGroups,
            rowGroupsSorting,
        };
    }

    function normalizeFixturesOptions(
        fixtures: any,
        datasetId: number | null,
        directionLogic: string
    ): LayoutGridConfigMap['fixtures'] {
        const { initialOptions } = layoutsInitialState.selectedLayout.fixtures;

        let rowGroups = fixtures.rowGroups;
        if (!rowGroups || rowGroups.length === 0) {
            rowGroups = getDefaultFixturesRowGroups(datasetId);
            rowGroups.forEach((rg) => {
                rg.checked = false;
            });
        }
        let rowGroupsSorting = fixtures.rowGroupsSorting;

        if (
            !rowGroupsSorting ||
            rowGroupsSorting.length === 0 ||
            !ensureAreasGroupingByDataset(datasetId, rowGroupsSorting)
        ) {
            rowGroupsSorting = getDefaultFixturesRowGroupsSorting(
                datasetId,
                directionLogic
            );
        }

        return {
            filterOptions: fixtures.filters ?? {},
            columnOptions: fixtures.columnState ?? [],
            vesselOptions:
                fixtures.vesselOptions || initialOptions.vesselOptions,
            shouldOnlyDisplayRumouredFixtures:
                fixtures.shouldOnlyDisplayRumouredFixtures ||
                initialOptions.shouldOnlyDisplayRumouredFixtures,
            shouldShowDeletedFixtures:
                (fixtures.shouldShowDeletedFixtures === undefined
                    ? fetchShowDeletedFixtures()
                    : fixtures.shouldShowDeletedFixtures) ||
                initialOptions.shouldShowDeletedFixtures,
            rowGroups,
            rowGroupsSorting,
        };
    }

    export function normalize(
        data: SavedLayoutModel,
        datasetId: number | null
    ): SelectedLayoutModel {
        const orders = data.orders ? JSON.parse(data.orders) : {};
        const fixtures = data.fixtures ? JSON.parse(data.fixtures) : {};
        const commonSettings =
            data.commonSettings ||
            _.cloneDeep(layoutsInitialState.selectedLayout.commonSettings);

        const normalizedOrders = normalizeOrdersOptions(
            orders,
            datasetId,
            commonSettings.directionLogic
        );
        const normalizedFixtures = normalizeFixturesOptions(
            fixtures,
            datasetId,
            commonSettings.directionLogic
        );

        return {
            selectedLayoutId: data.id,
            name: data.name,
            isTemplate: data.isTemplate,
            orders: {
                initialOptions: normalizedOrders,
                currentOptions: _.cloneDeep(normalizedOrders),
                hasRecentChanges: false,
                hasDifferencies: {},
                collapsedRowGroups:
                    orders.collapsedRowGroups ||
                    layoutsInitialState.selectedLayout.orders
                        .collapsedRowGroups,
            },
            fixtures: {
                initialOptions: normalizedFixtures,
                currentOptions: _.cloneDeep(normalizedFixtures),
                hasRecentChanges: false,
                hasDifferencies: {},
                collapsedRowGroups:
                    fixtures.collapsedRowGroups ||
                    layoutsInitialState.selectedLayout.fixtures
                        .collapsedRowGroups,
            },
            commonSettings: {
                directionLogic: {
                    initialState: commonSettings.directionLogic,
                    currentState: commonSettings.directionLogic,
                },
                quantityFormat: {
                    initialState: commonSettings.quantityFormat,
                    currentState: commonSettings.quantityFormat,
                },
                defaultType: {
                    initialState: commonSettings.defaultType,
                    currentState: commonSettings.defaultType,
                },
            },
            areThereAnyUnsavedLayoutChanges: false,
        };
    }
}

export function resolveCurrentLayout(
    state: LayoutsState
): SavedLayoutModel | undefined {
    const layoutsToSearch = !state.selectedDatasetId
        ? state.allLayouts
        : state.allLayouts.filter(
              (x) =>
                  !x.isTemplate &&
                  (!x.datasetId || x.datasetId === state.selectedDatasetId)
          );

    let selected: SavedLayoutModel | undefined;
    if (state.selectedLayout.selectedLayoutId) {
        selected = layoutsToSearch.find(
            (x) => x.id === state.selectedLayout.selectedLayoutId
        );
    }

    if (!selected) {
        selected = layoutsToSearch.find((x) => x.isPreferred);
    }
    if (!selected) {
        selected = layoutsToSearch[0];
    }

    return selected;
}

export function createInitialLayout(
    selectedLayout: SelectedLayoutModel,
    datasetId: number | null,
    gridType: keyof LayoutGridConfigMap,
    columnOptions: LayoutGridColumn[] | undefined
): Pick<SavedLayoutModel, 'fixtures' | 'orders' | 'commonSettings'> {
    const layoutToCreate = _.cloneDeep(selectedLayout);
    layoutToCreate.orders.currentOptions.rowGroups =
        getDefaultOrdersRowGroups(datasetId);
    layoutToCreate.orders.currentOptions.rowGroupsSorting =
        getDefaultOrdersRowGroupsSorting(
            datasetId,
            layoutToCreate.commonSettings.directionLogic.currentState
        );
    layoutToCreate.fixtures.currentOptions.rowGroups =
        getDefaultFixturesRowGroups(datasetId);
    layoutToCreate.fixtures.currentOptions.rowGroupsSorting =
        getDefaultFixturesRowGroupsSorting(
            datasetId,
            layoutToCreate.commonSettings.directionLogic.currentState
        );
    const dataset = getDataset(datasetId);
    if (dataset) {
        layoutToCreate.commonSettings.quantityFormat.currentState =
            dataset.defaultQuantityFormat;
        if (dataset.defaultType) {
            layoutToCreate.commonSettings.defaultType.currentState =
                dataset.defaultType;
        }
        if (columnOptions) {
            layoutToCreate[gridType].currentOptions.columnOptions =
                LayoutsApi.getDatasetDefaultColumns(
                    columnOptions,
                    gridType === 'fixtures'
                        ? dataset.defaultFixtureColumnsToShow
                        : dataset.defaultOrderColumnsToShow,
                    datasetId
                );
            if (gridType === 'fixtures') {
                layoutToCreate.fixtures.currentOptions.vesselOptions =
                    dataset.vesselOptions;
            }
        }
    }
    return LayoutSerializer.serializeLayoutData(layoutToCreate);
}
