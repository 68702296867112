import { DATASET_SPECIALISED } from './Datasets';

export const typesByDataset = (datasetId) => {
    switch (datasetId) {
        case DATASET_SPECIALISED:
            return ['VOY', 'TCT', 'PERIOD', 'COA', 'UNKNOWN', 'SALE'];
        default:
            return ['VOY', 'TCT', 'PERIOD', 'COA', 'UNKNOWN'];
    }
};

export const groupSortingByType = (datasetId) => {
    switch (datasetId) {
        case DATASET_SPECIALISED:
            return ['PERIOD', 'TCT', 'VOY', 'COA', 'UNKNOWN', 'SALE'];
        default:
            return ['PERIOD', 'TCT', 'VOY', 'COA', 'UNKNOWN'];
    }
};
