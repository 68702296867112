const colors = {
    grey1: '#808080',
    grey2: '#a6a6a6',
    grey3: '#d3d3d3',
    grey4: '#eeeeee',
    grey5: '#B0B0B0',
    grey6: '#c0c0c0',
    red: '#ff1744',
    red2: '#7a0000',
    black: '#000000',
    green: '#00a65a',
    white: '#ffffff',
    burgundy: '#ae2f5a',
    blue: '#2196F3',
    lightBlue: '#00A1D8',
    pink2: '#f7cdd3',
    pink3: '#ee95a2',
    pink4: '#dd8aa5',
    darkblue: '#4d5a8d',
};

export default colors;
