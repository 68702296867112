import { v4 as uuid } from 'uuid';

export const generateEmail = ({ subject, content, images }) => {
    const emailId = uuid();
    const emailContent = renderEmailToBase64(content);

    let message = 'X-Unsent: 1 \n';
    message += 'MIME-Version: 1.0 \n';
    message += 'Subject:' + subject + '\n';
    message += 'Content-Type: multipart/related; type="text/html"; \n';
    message += ' boundary=--boundary_1_' + emailId + '\n\n\n';
    message += '----boundary_1_' + emailId + '\n';
    message += 'Content-Type: text/html; charset=utf-8\n';
    message += 'Content-Transfer-Encoding: base64\n\n';
    message += emailContent;

    images.forEach((image) => {
        message += '----boundary_1_' + emailId + '\n';
        message += 'Content-Type: ' + image.type + '\n';
        message += 'Content-Transfer-Encoding: base64\n';
        message += 'Content-ID: <' + image.id + '>\n\n';
        message += formatTextWrap(image.content);
        message += '----boundary_1_' + emailId + '--';
    });

    return {
        filename: subject.replace(/\s/g, '') + '.eml',
        data: message,
    };
};

const formatTextWrap = (text) => {
    return text.replace(/([^\n]{1,68})/g, '$1\n');
};
const renderEmailToBase64 = (content) => {
    const doctype =
        '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">';
    return formatTextWrap(
        btoa(unescape(encodeURIComponent(doctype + content)))
    );
};
