import {
    DATASET_DRY_CARGO,
    DATASET_LPG_VLGC,
    DATASET_PCG,
    DATASET_SPECIALISED,
} from '../models/Datasets';

export const getDefaultOrdersRowGroups = (datasetId) => {
    if (!datasetId) return [];
    switch (datasetId) {
        case DATASET_DRY_CARGO:
            return [
                { colId: 'thirdPartyCharterer', checked: false },
                { colId: 'cargoQuantity', checked: false },
                { colId: 'cargoParts', checked: false },
                { colId: 'loadZone', checked: false },
                { colId: 'areasGeared', checked: false },
                { colId: 'areas', checked: true },
                { colId: 'groupName', checked: false },
                { colId: 'quoted', checked: false },
                { colId: 'direction', checked: false },
                { colId: 'type', checked: false },
                { colId: 'vesselCategory', checked: false },
            ];
        case DATASET_SPECIALISED:
            return [
                { colId: 'charterer', checked: false },
                { colId: 'cargoQuantity', checked: false },
                { colId: 'cargoParts', checked: false },
                { colId: 'loadZone', checked: false },
                { colId: 'areas', checked: true },
                { colId: 'groupName', checked: false },
                { colId: 'quoted', checked: false },
                { colId: 'type', checked: false },
                { colId: 'buyer', checked: false },
                { colId: 'opportunity', checked: false },
                { colId: 'operator', checked: false },
            ];
        default:
            return [
                { colId: 'charterer', checked: false },
                { colId: 'cargoQuantity', checked: false },
                { colId: 'cargoParts', checked: false },
                { colId: 'loadZone', checked: false },
                { colId: 'areas', checked: true },
                { colId: 'groupName', checked: false },
                { colId: 'quoted', checked: false },
                { colId: 'type', checked: false },
                { colId: 'vesselCategory', checked: false },
            ];
    }
};

export const getDefaultFixturesRowGroups = (datasetId) => {
    switch (datasetId) {
        case DATASET_DRY_CARGO:
            return [
                { colId: 'thirdPartyCharterer', checked: false },
                { colId: 'cargoQuantity', checked: false },
                { colId: 'cargoParts', checked: false },
                { colId: 'loadZone', checked: false },
                { colId: 'areasGeared', checked: false },
                { colId: 'areas', checked: false },
                { colId: 'groupName', checked: false },
                { colId: 'quoted', checked: false },
                { colId: 'direction', checked: false },
                { colId: 'vesselCategory', checked: false },
                { colId: 'type', checked: false },
            ];
        case DATASET_PCG:
            return [
                { colId: 'charterer', checked: false },
                { colId: 'cargoQuantity', checked: false },
                { colId: 'cargoParts', checked: false },
                { colId: 'loadZone', checked: false },
                { colId: 'areas', checked: false },
                { colId: 'groupName', checked: false },
                { colId: 'vesselCategory', checked: false },
                { colId: 'type', checked: false },
            ];
        case DATASET_LPG_VLGC:
            return [
                { colId: 'charterer', checked: false },
                { colId: 'cargoQuantity', checked: false },
                { colId: 'cargoParts', checked: false },
                { colId: 'loadZone', checked: false },
                { colId: 'areas', checked: false },
                { colId: 'groupName', checked: false },
                { colId: 'vesselCategory', checked: false },
                { colId: 'type', checked: true },
            ];
        default:
            return [
                { colId: 'charterer', checked: false },
                { colId: 'cargoQuantity', checked: false },
                { colId: 'cargoParts', checked: false },
                { colId: 'loadZone', checked: false },
                { colId: 'areas', checked: false },
                { colId: 'groupName', checked: false },
                { colId: 'quoted', checked: false },
                { colId: 'vesselCategory', checked: false },
                { colId: 'type', checked: false },
            ];
    }
};
