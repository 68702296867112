import LaycanEditor from '../grid/editors/LaycanEditor';
import LaycanFormatter from '../grid/formatters/LaycanFormatter';
import LaycanKeyCreator from '../grid/keyCreators/LaycanKeyCreator';
import {
    LaycanComparator,
    LaycanFromComparator,
    LaycanToComparator,
} from '../grid/comparators/LaycanComparator';
import LaycanFilterComparator from '../grid/comparators/LaycanFilterComparator';
import LaycanFromFormatter from '../grid/formatters/LaycanFromFormatter';
import LaycanToFormatter from '../grid/formatters/LaycanToFormatter';
import LaycanValueGetter from '../grid/valueGetters/LaycanValueGetter';
import LaycanMonthValueGetter from '../grid/valueGetters/LaycanMonthValueGetter';
import LaycanDecadeValueGetter from '../grid/valueGetters/LaycanDecadeValueGetter';
import LaycanYearValueGetter from '../grid/valueGetters/LaycanYearValueGetter';

import DefaultDateFormatter from '../grid/formatters/DefaultDateFormatter';
import DefaultDateTimeFormatter from '../grid/formatters/DefaultDateTimeFormatter';
import DateComparator from '../grid/comparators/DateComparator';
import DateEditor from '../grid/editors/DateEditor';

import MultipleFreightPortBasisFormatter from '../grid/formatters/MultipleFreightPortBasisFormatter';
import FreightPortBasisEditor from '../grid/editors/FreightPortBasisEditor';
import MultiFreightPortBasisComparator from '../grid/comparators/MultiFreightPortBasisComparator';
import FreightPortBasisKeyCreator from '../grid/keyCreators/FreightPortBasisKeyCreator';

import { ratesHeading } from '_legacy/modules/columns/rate';
import { cargoQuantityPartsHeading } from '_legacy/modules/columns/quantity';
import { durationPartsHeading } from '_legacy/modules/columns/duration';
import { clarksonsBrokerHeadingForFixtures } from '_legacy/modules/columns/clarksonsBroker';
import { updatedByHeading } from '_legacy/modules/columns/updatedBy';
import {
    vesselNameHeading,
    vesselImoHeading,
    vesselEniHeading,
    vesselYobHeading,
    vesselCategoryHeading,
    vesselDwtHeading,
    vesselScrubberHeading,
    vesselYardHeading,
    vesselDesignHeading,
    vesselImoClassHeading,
    vesselIceClassNotationHeading,
    vesselNitrogenPlantHeading,
    vesselSegsHeading,
} from '_legacy/modules/columns/vessel';
import { thirdPartyOwnerHeading } from '_legacy/modules/columns/thirdPartyOwner';
import { thirdPartyChartererHeading } from '_legacy/modules/columns/thirdPatyCharterer';

import CargoEditor from '../grid/editors/CargoEditor';
import MultipleCargoFormatter from '../grid/formatters/MultipleCargoFormatter';
import CargoPartsComparator from '../grid/comparators/CargoPartsComparator';
import CargoPartsKeyCreator from '../grid/keyCreators/CargoPartsKeyCreator';
import NumericEditor from '../grid/editors/NumericEditor';
import MultipleCompanyFormatter from '../grid/formatters/MultipleCompanyFormatter';
import CompanyFormatter from '_legacy/modules/shared/columns/formatters/CompanyFormatter';
import CompanyKeyCreator from '_legacy/modules/shared/columns/keyCreators/CompanyKeyCreator';
import ChartererEditor from '../grid/editors/ChartererEditor';
import OwnerEditor from '../grid/editors/OwnerEditor';
import BrokerEditor from '../grid/editors/BrokerEditor';
import NamePropertyComparator from '../grid/comparators/NamePropertyComparator';
import suppressOnEdit from '../grid/columnDefaults/suppressOnEdit';
import allowUpAndDownOnEdit from '../grid/columnDefaults/allowUpAndDownOnEdit';
import defaultObjEquals from '../grid/columnDefaults/defaultObjEquals';

import LocationEditor from '../grid/editors/LocationEditor';
import LocationsFormatter from '../../components/grid/formatters/LocationsFormatter';
import LocationPartsKeyCreator from '../grid/keyCreators/LocationPartsKeyCreator';
import LocationPartsComparator from '../grid/comparators/locationPartsComparator';

import TypeFormatter, {
    AllTypeFormatter,
} from '../grid/formatters/TypeFormatter';

import {
    StatusFilterFormatter,
    StatusFormatter,
} from '../grid/formatters/StatusFormatter';
import {
    DefaultFixtureStatusMapping,
    FetchMappingSelectDisplayValue,
} from '../../data/mappings';
import StatusComparator from '../grid/comparators/StatusComparator';
import StatusRenderer from '../grid/renderers/StatusRenderer';

import {
    TrackingFormatter,
    TrackingFormatterSelectDisplayValue,
} from '../grid/formatters/TrackingFormatter';

import BrokersComparator from '../grid/comparators/BrokersComparator';
import BrokersKeyCreator from '../grid/keyCreators/BrokersKeyCreator';

import ZoneEditor from '../grid/editors/ZoneEditor';
import ZoneKeyCreator from '../grid/keyCreators/ZoneKeyCreator';

import LoadZoneComparator from '../grid/comparators/LoadZoneComparator';
import DischargeZoneComparator from '../grid/comparators/DischargeZoneComparator';

import {
    commonRowAndCellClassRules,
    statusClassRules,
} from '../grid/classRules/classRules';

import BlanksFilterRenderer from '../grid/renderers/BlanksFilterRenderer';
import UnknownFilterRenderer from '../grid/renderers/UnknownFilterRenderer';
import TooltipRenderer from '../grid/renderers/TooltipRenderer';
import GroupNameRenderer from '../grid/renderers/GroupNameRenderer';

import naturalSortComparator from '../grid/comparators/NaturalSortComparator';
import defaultSetFilterParams from '../grid/columnDefaults/defaultSetFilterParams';

import SelectEditor from '_legacy/modules/shared/columns/editors/SelectEditor';
import VisibilityRenderer from '../grid/renderers/VisibilityRenderer';

import VoyageActivityRenderer from '../grid/renderers/VoyageActivityRenderer';

import QuotedFormatter, {
    QuotedFieldFormatter,
} from '../grid/formatters/QuotedFormatter';
import QuotedKeyCreator from '../grid/keyCreators/QuotedKeyCreator';
import { areasByDataset, getAreasGeared } from '../../models/Areas';
import {
    blankKeyText,
    unassignedChartererText,
    unassignedAreaText,
    unassignedAreaGearedText,
    unassignedDirectionText,
    unassignedLoadZoneText,
} from '../../constants/gridText';
import voyageActivityComparator from '../grid/comparators/voyageActivityComparator';

import CommissionEditor from '../grid/editors/CommissionEditor';
import ParcelsEditor from '../grid/editors/ParcelsEditor';

import ThirdPartyBrokerEditor from '../grid/editors/ThirdPartyBrokerEditor';
import MultipleThirdPartyCompanyFormatter from '../grid/formatters/MultipleThirdPartyCompanyFormatter';

import DirectionValueGetter from '../grid/valueGetters/DirectionValueGetter';

import { channelValues } from '_legacy/constants/channelValues';

import {
    SEPARATOR_FORWARD_SLASH,
    SEPARATOR_PLUS,
} from '../../models/common/EntityPart';
import CommissionFormatter from '../grid/formatters/CommissionFormatter';
import CommissionComparator from '../grid/comparators/CommissionComparator';
import CommissionKeyCreator from '../grid/keyCreators/CommissionKeyCreator';
import { getDataset } from '_legacy/models/Datasets';
import { fixtureDeliveryTermHeading } from '_legacy/modules/columns/deliveryTerm';
import SalePriceEditor from '../grid/editors/SalePriceEditor';
import { formatSalePrice } from '_legacy/services/SalePriceFormatterService';
import { SalePriceComparator } from '_legacy/modules/columns/quantity/comparators/SalePriceComparator';
import DateFormatter from '../grid/formatters/DateFormatter';
import { buyerHeading } from '_legacy/modules/columns/buyer/heading/buyerHeading';
import { operatorHeading } from '_legacy/modules/columns/operator/headings/operatorHeading';
import { opportunityHeading } from '_legacy/modules/columns/opportunity/heading/opportunityHeading';
import { typesByDataset } from '_legacy/models/Type';

const isEditableRow = (params) => {
    const isEditable =
        params &&
        params.data &&
        params.data.state !== 'deleted' &&
        params.context.canEdit;

    return isEditable;
};

const isEditableNonSpecialisedRow = (params) =>
    isEditableRow(params) && params.context.datasetId !== 13;

const checkboxHeading = {
    colId: 'isSelected',
    pinned: 'left',
    headerName: '',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    lockPinned: true,
    suppressMenu: true,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
    lockPosition: 'left',
    width: 24,
    minWidth: 35,
    enableCellChangeFlash: false,
};

const privacyHeading = {
    colId: 'privacy',
    headerName: '',
    field: 'privacy',
    editable: false,
    cellRendererFramework: VisibilityRenderer,
    headerClass: 'grid-heading-privacy',
    cellClass: 'grid-cell-privacy',
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    lockPinned: true,
    pinned: 'left',
    suppressMovable: true,
    filter: 'visibilityFilter',
    suppressColumnsToolPanel: true,
    width: 60,
    minWidth: 60,
};

const statusHeading = {
    colId: 'status',
    headerName: 'Status',
    field: 'status',
    cellEditorFramework: SelectEditor,
    cellEditorParams: {
        values: DefaultFixtureStatusMapping.map((m) => m.id),
        formatValue: FetchMappingSelectDisplayValue,
    },
    suppressKeyboardEvent: allowUpAndDownOnEdit(),
    valueFormatter: StatusFormatter,
    cellRendererFramework: StatusRenderer,
    editable: isEditableRow,
    comparator: StatusComparator,
    cellClassRules: {
        ...statusClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
        valueFormatter: StatusFilterFormatter,
    },
    tooltipValueGetter: (params) =>
        FetchMappingSelectDisplayValue(params.value, params.data.datasetId),
    width: 67,
};

const reportedDateHeading = {
    colId: 'reportedDate',
    headerName: 'Rptd Date',
    field: 'reportedDate',
    valueFormatter: DefaultDateFormatter,
    cellEditorFramework: DateEditor,
    editable: isEditableRow,
    equals: defaultObjEquals,
    comparator: DateComparator,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'dateFilter',
    width: 85,
};

const minRedelivery = {
    colId: 'minRedelivery',
    headerName: 'Min Redel',
    field: 'minRedelivery',
    headerTooltip: 'Read-only, auto-calculated based on L/C and firm Duration',
    valueFormatter: DefaultDateFormatter,
    cellEditorFramework: DateEditor,
    editable: false,
    equals: defaultObjEquals,
    comparator: DateComparator,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'dateFilter',
    width: 85,
    hide: true,
};

const maxRedelivery = {
    colId: 'maxRedelivery',
    headerName: 'Max Redel',
    field: 'maxRedelivery',
    headerTooltip: 'Read-only, auto-calculated based on L/C and firm Duration',
    valueFormatter: DefaultDateFormatter,
    cellEditorFramework: DateEditor,
    editable: false,
    equals: defaultObjEquals,
    comparator: DateComparator,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'dateFilter',
    width: 85,
    hide: true,
};

const updatedTimeHeading = {
    colId: 'updatedDateTime',
    headerName: 'Updated On',
    field: 'lastUpdatedDateTimeByUser',
    valueFormatter: DefaultDateTimeFormatter,
    cellEditorFramework: DateEditor,
    editable: false,
    equals: defaultObjEquals,
    comparator: DateComparator,
    width: 132,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'dateFilter',
    hide: true,
};

const rateHeading = {
    colId: 'rate',
    headerName: 'Rate',
    field: 'rate',
    editable: isEditableNonSpecialisedRow,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    width: 100,
    suppressPaste: false,
};

const laycanHeading = {
    colId: 'laycan',
    headerName: 'L/C',
    field: 'laycan',
    headerTooltip:
        'The date the cargo will be loaded on a VOY or where the vessel is delivered on a TCT',
    editable: isEditableRow,
    cellEditorFramework: LaycanEditor,
    valueFormatter: LaycanFormatter,
    valueGetter: LaycanValueGetter,
    comparator: LaycanComparator,
    equals: defaultObjEquals,
    keyCreator: LaycanKeyCreator,
    filter: 'laycanFilter',
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    width: 100,
};

const laycanFromHeading = {
    colId: 'laycanFrom',
    headerName: 'L/C From',
    field: 'laycan',
    editable: false,
    sortable: true,
    suppressMenu: false,
    valueFormatter: LaycanFromFormatter,
    comparator: LaycanFromComparator,
    hide: true,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'agDateColumnFilter',
    filterParams: {
        comparator: LaycanFilterComparator,
        suppressAndOrCondition: true,
        inRangeInclusive: true,
        filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
        defaultOption: 'inRange',
    },
    width: 80,
};

const laycanToHeading = {
    colId: 'laycanTo',
    editable: false,
    headerName: 'L/C To',
    field: 'laycan',
    sortable: true,
    suppressMenu: true,
    valueFormatter: LaycanToFormatter,
    comparator: LaycanToComparator,
    hide: true,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    width: 80,
};

const laycanYearHeading = {
    colId: 'laycanYear',
    editable: false,
    headerName: 'Year',
    field: 'laycan',
    valueGetter: LaycanYearValueGetter,
    hide: true,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    suppressMenu: true,
    filter: false,
    width: 80,
};

const laycanMonthHeading = {
    colId: 'laycanMonth',
    editable: false,
    headerName: 'Month',
    field: 'laycan',
    valueGetter: LaycanMonthValueGetter,
    hide: true,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    suppressMenu: true,
    filter: false,
    width: 80,
};

const laycanDecadeHeading = {
    colId: 'laycanDecade',
    editable: false,
    headerName: 'Decade',
    field: 'laycan',
    valueGetter: LaycanDecadeValueGetter,
    hide: true,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    suppressMenu: true,
    filter: false,
    width: 80,
};

const commentsHeading = {
    colId: 'comments',
    headerName: 'Comments',
    field: 'comments',
    editable: isEditableRow,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    cellRenderer: TooltipRenderer,
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    width: 120,
    suppressPaste: false,
};

const dischargeLocationHeading = {
    colId: 'dischargeLocationParts',
    headerName: 'Disch',
    field: 'dischargeLocationParts',
    editable: isEditableRow,
    cellEditorFramework: LocationEditor,
    cellEditorParams: {
        defaultSeperatorCharacter: SEPARATOR_FORWARD_SLASH,
        blockedCharacters: [],
    },
    cellRenderer: (location) =>
        TooltipRenderer({ value: location.valueFormatted }),
    valueFormatter: LocationsFormatter,
    equals: defaultObjEquals,
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: LocationPartsComparator,
    keyCreator: LocationPartsKeyCreator,
    filter: 'locationsFilter',
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    width: 120,
};

const redeliveryLocationHeading = {
    colId: 'redeliveryLocationParts',
    headerName: 'Redelivery',
    field: 'redeliveryLocationParts',
    editable: isEditableRow,
    cellEditorFramework: LocationEditor,
    cellEditorParams: {
        defaultSeperatorCharacter: SEPARATOR_FORWARD_SLASH,
        blockedCharacters: [],
    },
    cellRenderer: (location) =>
        TooltipRenderer({ value: location.valueFormatted }),
    valueFormatter: LocationsFormatter,
    equals: defaultObjEquals,
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: LocationPartsComparator,
    keyCreator: LocationPartsKeyCreator,
    filter: 'locationsFilter',
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    width: 120,
};

const hireExpiryHeading = {
    colId: 'hireExpiry',
    headerName: 'Hire Expiry',
    field: 'hireExpiry',
    hide: true,
    valueFormatter: DefaultDateFormatter,
    cellEditorFramework: DateEditor,
    editable: isEditableRow,
    equals: defaultObjEquals,
    comparator: DateComparator,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'dateFilter',
    width: 150,
};

const cargoPartsHeading = {
    colId: 'cargoParts',
    headerName: 'Cgo',
    field: 'cargoParts',
    enableRowGroup: true,
    editable: isEditableRow,
    valueFormatter: MultipleCargoFormatter,
    cellEditorFramework: CargoEditor,
    cellRenderer: (cargo) => TooltipRenderer({ value: cargo.valueFormatted }),
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: CargoPartsComparator,
    equals: defaultObjEquals,
    keyCreator: CargoPartsKeyCreator,
    filter: 'cargoesFilter',
    width: 120,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
};

const freightPortBasisHeading = {
    colId: 'freightPortBasis',
    headerName: 'Freight Port Basis',
    field: 'freightPortBasis',
    editable: isEditableRow,
    valueFormatter: MultipleFreightPortBasisFormatter,
    cellEditorFramework: FreightPortBasisEditor,
    cellRenderer: (port) => TooltipRenderer({ value: port.valueFormatted }),
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: MultiFreightPortBasisComparator,
    equals: defaultObjEquals,
    keyCreator: FreightPortBasisKeyCreator,
    width: 120,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    hide: true,
};

const loadLocationHeading = {
    colId: 'loadLocationParts',
    headerName: 'Load',
    field: 'loadLocationParts',
    editable: isEditableRow,
    cellEditorFramework: LocationEditor,
    cellEditorParams: {
        defaultSeperatorCharacter: SEPARATOR_FORWARD_SLASH,
        blockedCharacters: [],
    },
    cellRenderer: (location) =>
        TooltipRenderer({ value: location.valueFormatted }),
    valueFormatter: LocationsFormatter,
    equals: defaultObjEquals,
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: LocationPartsComparator,
    keyCreator: LocationPartsKeyCreator,
    filter: 'locationsFilter',
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    width: 100,
};

const deliveryLocationHeading = {
    colId: 'deliveryLocationParts',
    headerName: 'Delivery',
    field: 'deliveryLocationParts',
    editable: isEditableRow,
    cellEditorFramework: LocationEditor,
    cellEditorParams: {
        defaultSeperatorCharacter: SEPARATOR_FORWARD_SLASH,
        blockedCharacters: [],
    },
    cellRenderer: (location) =>
        TooltipRenderer({ value: location.valueFormatted }),
    valueFormatter: LocationsFormatter,
    equals: defaultObjEquals,
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: LocationPartsComparator,
    keyCreator: LocationPartsKeyCreator,
    filter: 'locationsFilter',
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    width: 100,
};

const loadLocationZoneHeading = {
    colId: 'loadZone',
    headerName: 'Load Z',
    field: 'loadZone',
    enableRowGroup: true,
    editable: isEditableNonSpecialisedRow,
    cellEditorFramework: ZoneEditor,
    suppressKeyboardEvent: suppressOnEdit(),
    keyCreator: ({ value }) => {
        if (!value) {
            return unassignedLoadZoneText;
        }
        return value;
    },
    comparator: LoadZoneComparator,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    width: 100,
};

const dischargeLocationZoneHeading = {
    colId: 'dischargeZone',
    headerName: 'Disch Z',
    field: 'dischargeZone',
    editable: isEditableNonSpecialisedRow,
    cellEditorFramework: ZoneEditor,
    suppressKeyboardEvent: suppressOnEdit(),
    keyCreator: ZoneKeyCreator,
    comparator: DischargeZoneComparator,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    width: 100,
};

const areaHeading = {
    colId: 'areas',
    headerValueGetter: (params) =>
        getDataset(params.context.datasetId).headingsMapping['areas'],
    field: 'areas',
    enableRowGroup: true,
    editable: isEditableNonSpecialisedRow,
    sortable: true,
    keyCreator: ({ value }) => {
        if (!value) {
            return unassignedAreaText;
        }
        return value;
    },
    cellEditorFramework: SelectEditor,
    cellEditorParams: {
        valuesByDataset: areasByDataset,
    },
    suppressKeyboardEvent: allowUpAndDownOnEdit(),
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    hide: true,
    width: 100,
};

const areaGearedHeading = {
    colId: 'areasGeared',
    headerName: 'Area Geared',
    field: 'areasGeared',
    editable: isEditableRow,
    enableRowGroup: true,
    sortable: true,
    keyCreator: ({ value }) => {
        if (!value) {
            return unassignedAreaGearedText;
        }
        return value;
    },
    cellEditorFramework: SelectEditor,
    cellEditorParams: {
        valuesByDataset: getAreasGeared,
    },
    suppressKeyboardEvent: allowUpAndDownOnEdit(),
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    hide: true,
    width: 100,
};

const chartererHeading = {
    colId: 'charterer',
    headerName: 'Charterer',
    field: 'charterer',
    enableRowGroup: true,
    editable: isEditableRow,
    valueFormatter: CompanyFormatter,
    cellEditorFramework: ChartererEditor,
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: NamePropertyComparator,
    equals: defaultObjEquals,
    keyCreator: (params) =>
        CompanyKeyCreator({ ...params, defaultValue: unassignedChartererText }),
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
        cellRenderer: BlanksFilterRenderer,
    },
    width: 120,
};

const thirdPartyBrokersHeading = {
    colId: 'thirdPartyBrokers',
    headerName: 'Broker',
    field: 'thirdPartyBrokers',
    editable: isEditableRow,
    valueFormatter: MultipleThirdPartyCompanyFormatter,
    cellEditorFramework: ThirdPartyBrokerEditor,
    cellEditorParams: {
        blockedCharacters: [SEPARATOR_FORWARD_SLASH],
        seperatorCharacters: [SEPARATOR_PLUS],
    },
    cellRenderer: (broker) => TooltipRenderer({ value: broker.valueFormatted }),
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: BrokersComparator,
    equals: defaultObjEquals,
    keyCreator: BrokersKeyCreator,
    width: 120,
    filter: 'companiesFilter',
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
};

const demurrageHeading = {
    colId: 'demurrage',
    headerName: 'Dem (K)',
    field: 'demurrage',
    editable: isEditableRow,
    cellEditorFramework: NumericEditor,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    width: 65,
};

const brokerHeading = {
    colId: 'brokers',
    headerName: 'Broker',
    field: 'brokers',
    editable: isEditableRow,
    valueFormatter: MultipleCompanyFormatter,
    cellEditorFramework: BrokerEditor,
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: BrokersComparator,
    equals: defaultObjEquals,
    keyCreator: BrokersKeyCreator,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    cellEditorParams: { requireConfirmation: true },
    width: 120,
};

const typeHeading = {
    colId: 'type',
    headerName: 'Type',
    field: 'type',
    editable: isEditableRow,
    valueFormatter: TypeFormatter,
    cellEditorFramework: SelectEditor,
    cellEditorParams: {
        valuesByDataset: typesByDataset,
        formatValue: AllTypeFormatter,
    },
    suppressKeyboardEvent: allowUpAndDownOnEdit(),
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: UnknownFilterRenderer,
        comparator: naturalSortComparator,
    },
    width: 67,
};

const ownerHeading = {
    colId: 'owner',
    headerName: 'Owner',
    field: 'owner',
    editable: isEditableRow,
    valueFormatter: CompanyFormatter,
    cellEditorFramework: OwnerEditor,
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: NamePropertyComparator,
    equals: defaultObjEquals,
    keyCreator: CompanyKeyCreator,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
        cellRenderer: BlanksFilterRenderer,
    },
    width: 120,
};

const actualDirectionHeading = {
    colId: 'actualDirection',
    headerName: 'E-W',
    field: 'actualDirection',
    editable: isEditableRow,
    cellEditorFramework: SelectEditor,
    cellEditorParams: {
        values: ['', 'E', 'W'],
    },
    suppressKeyboardEvent: allowUpAndDownOnEdit(),
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    hide: true,
    width: 67,
};

const directionHeading = {
    colId: 'direction',
    headerName: 'Direction',
    field: 'direction',
    editable: false,
    enableRowGroup: true,
    type: ['privatable'],
    valueGetter: DirectionValueGetter,
    keyCreator: ({ value }) => {
        if (!value) {
            return unassignedDirectionText;
        }
        return value;
    },
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    hide: true,
    width: 100,
};

const channelHeading = {
    colId: 'channel',
    headerName: 'Channel',
    field: 'channel',
    editable: isEditableRow,
    cellEditorFramework: SelectEditor,
    cellEditorParams: {
        values: channelValues,
    },
    suppressKeyboardEvent: allowUpAndDownOnEdit(),
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    hide: false,
    width: 125,
};

const trackingHeading = {
    colId: 'tracking',
    headerName: 'Tracking',
    field: 'tracking',
    editable: isEditableRow,
    cellEditorFramework: SelectEditor,
    valueFormatter: TrackingFormatter,
    keyCreator: ({ value }) => (value && value.length ? value : 'N'),
    cellEditorParams: {
        values: ['N', 'Y', 'COMP'],
        formatValue: TrackingFormatterSelectDisplayValue,
    },
    suppressKeyboardEvent: allowUpAndDownOnEdit(),
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
    },
    hide: true,
    width: 100,
};

const lastOpenLocationHeading = {
    colId: 'lastOpenLocationParts',
    headerName: 'Last Open Location',
    field: 'lastOpenLocationParts',
    editable: isEditableRow,
    cellEditorFramework: LocationEditor,
    suppressKeyboardEvent: suppressOnEdit(),
    keyCreator: LocationPartsKeyCreator,
    valueFormatter: LocationsFormatter,
    equals: defaultObjEquals,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    hide: true,
    width: 120,
};

const etaHeading = {
    colId: 'eta',
    headerName: 'ETA',
    field: 'eta',
    valueFormatter: DefaultDateFormatter,
    cellEditorFramework: DateEditor,
    editable: isEditableRow,
    equals: defaultObjEquals,
    comparator: DateComparator,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'dateFilter',
    hide: true,
    width: 85,
};

const lastOpenDateHeading = {
    colId: 'lastOpenDate',
    headerName: 'Last Open Date',
    field: 'lastOpenDate',
    valueFormatter: DefaultDateFormatter,
    cellEditorFramework: DateEditor,
    editable: isEditableRow,
    equals: defaultObjEquals,
    comparator: DateComparator,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'dateFilter',
    hide: true,
    width: 85,
};

const salePriceHeading = {
    colId: 'salePrice',
    headerName: 'Sale Price',
    field: 'salePrice',
    editable: true,
    hide: true,
    cellEditorFramework: SalePriceEditor,
    sortable: true,
    type: ['privatable'],
    width: 150,
    valueFormatter: (params) => formatSalePrice(params.value),
    comparator: SalePriceComparator,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'agMultiColumnFilter',
    filterParams: {
        filters: [
            {
                filter: 'salePriceFilter',
            },
            {
                filter: 'agSetColumnFilter',
                filterParams: {
                    ...defaultSetFilterParams,
                    comparator: SalePriceComparator,
                    refreshValuesOnOpen: true,
                    suppressRemoveEntries: true,
                    cellRenderer: BlanksFilterRenderer,
                    valueFormatter: (params) =>
                        formatSalePrice(params.value, true),
                },
            },
        ],
    },
};

const saleDateHeading = {
    colId: 'saleDate',
    headerName: 'Sale Date',
    field: 'saleDate',
    hide: true,
    valueFormatter: DefaultDateFormatter,
    cellEditorFramework: DateEditor,
    editable: isEditableRow,
    equals: defaultObjEquals,
    comparator: DateComparator,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'dateFilter',
    width: 150,
};

// We need this header in place so that we can use setDataValue() methods for the privateFields (cell privacy)
// column. Otherwise, it's ignored. It is not displayed and cannot be picked from column selector.
//
const privateFieldsHeading = {
    colId: 'privateFields',
    headerName: 'Private Fields',
    field: 'privateFields',
    editable: false,
    hide: true,
    suppressColumnsToolPanel: true,
};

// Same to private fields
const rumouredFieldsHeading = {
    colId: 'rumouredFields',
    headerName: 'Rumoured Fields',
    field: 'rumouredFields',
    editable: false,
    hide: true,
    suppressColumnsToolPanel: true,
};

const groupName = {
    colId: 'groupName',
    headerName: 'Group',
    field: 'groupName',
    editable: false,
    enableRowGroup: true,
    cellRendererFramework: GroupNameRenderer,
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
};

const rumouredHeading = {
    colId: 'rumoured',
    headerName: 'Rumoured',
    field: 'rumoured',
    editable: false,
    hide: true,
    suppressColumnsToolPanel: true,
};

const privateCommentsHeading = {
    colId: 'privateComments',
    headerName: 'Private Comments',
    field: 'privateComments',
    editable: isEditableRow,
    cellRenderer: TooltipRenderer,
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    hide: true,
    suppressPaste: false,
};

const actualLoadHeading = {
    colId: 'actualLoad',
    headerName: 'Actual Load',
    field: 'actualLoad',
    editable: isEditableRow,
    cellEditorFramework: LocationEditor,
    cellEditorParams: {
        defaultSeperatorCharacter: SEPARATOR_PLUS,
        blockedCharacters: [SEPARATOR_FORWARD_SLASH],
    },
    suppressKeyboardEvent: suppressOnEdit(),
    keyCreator: LocationPartsKeyCreator,
    valueFormatter: LocationsFormatter,
    equals: defaultObjEquals,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    hide: true,
    width: 120,
};

const actualDischargeHeading = {
    colId: 'actualDischarge',
    headerName: 'Actual Discharge',
    field: 'actualDischarge',
    editable: isEditableRow,
    cellEditorFramework: LocationEditor,
    cellEditorParams: {
        defaultSeperatorCharacter: SEPARATOR_PLUS,
        blockedCharacters: [SEPARATOR_FORWARD_SLASH],
    },
    suppressKeyboardEvent: suppressOnEdit(),
    keyCreator: LocationPartsKeyCreator,
    valueFormatter: LocationsFormatter,
    equals: defaultObjEquals,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    hide: true,
    width: 120,
};

const voyageActivityHeading = {
    colId: 'voyageActivity',
    headerName: 'Voyage Activity',
    field: 'voyageActivity',
    editable: false,
    type: ['privatable'],
    keyCreator: ({ value }) => (value && value.length ? value : blankKeyText),
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: voyageActivityComparator,
    },
    cellRendererFramework: VoyageActivityRenderer,
    hide: true,
    width: 120,
};

const quotedHeading = {
    colId: 'quoted',
    headerName: 'Quoted',
    field: 'quoted',
    enableRowGroup: true,
    editable: isEditableRow,
    valueFormatter: QuotedFormatter,
    cellEditorFramework: SelectEditor,
    keyCreator: QuotedKeyCreator,
    cellEditorParams: {
        values: ['Y', 'N'],
        formatValue: QuotedFieldFormatter,
    },
    suppressKeyboardEvent: allowUpAndDownOnEdit(),
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
    },
    hide: true,
    type: ['privatable'],
};

const offersHeading = {
    colId: 'offers',
    headerName: 'Offers',
    field: 'offers',
    editable: isEditableRow,
    width: 120,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    cellRenderer: TooltipRenderer,
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    hide: true,
    suppressPaste: false,
};

const commissionHeading = {
    colId: 'commission',
    headerName: 'Commission',
    field: 'commission',
    editable: isEditableRow,
    cellEditorFramework: CommissionEditor,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    cellRenderer: (commission) =>
        TooltipRenderer({ value: commission.valueFormatted }),
    valueFormatter: CommissionFormatter,
    equals: defaultObjEquals,
    keyCreator: CommissionKeyCreator,
    comparator: CommissionComparator,
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    width: 100,
    hide: true,
};

const parcelsHeading = {
    colId: 'parcels',
    headerName: 'Parcels',
    field: 'parcels',
    editable: isEditableRow,
    cellEditorFramework: ParcelsEditor,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    width: 100,
    hide: true,
};

const createdDateHeading = {
    colId: 'createdDateTime',
    headerName: 'Created Date',
    field: 'createdDateTime',
    valueFormatter: DateFormatter,
    cellEditorFramework: DateEditor,
    editable: false,
    equals: defaultObjEquals,
    comparator: DateComparator,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'dateFilter',
};

const headers = [
    checkboxHeading,
    privacyHeading,
    groupName,
    reportedDateHeading,
    updatedTimeHeading,
    updatedByHeading,
    vesselNameHeading,
    statusHeading,
    typeHeading,
    cargoQuantityPartsHeading,
    cargoPartsHeading,
    fixtureDeliveryTermHeading,
    deliveryLocationHeading,
    loadLocationHeading,
    dischargeLocationHeading,
    redeliveryLocationHeading,
    durationPartsHeading,
    hireExpiryHeading,
    freightPortBasisHeading,
    laycanHeading,
    laycanFromHeading,
    laycanToHeading,
    rateHeading,
    ratesHeading,
    chartererHeading,
    thirdPartyChartererHeading,
    thirdPartyOwnerHeading,
    thirdPartyBrokersHeading,
    clarksonsBrokerHeadingForFixtures,
    demurrageHeading,
    brokerHeading,
    buyerHeading,
    loadLocationZoneHeading,
    dischargeLocationZoneHeading,
    areaHeading,
    areaGearedHeading,
    ownerHeading,
    privateCommentsHeading,
    commentsHeading,
    trackingHeading,
    actualDirectionHeading,
    directionHeading,
    etaHeading,
    vesselImoHeading,
    vesselEniHeading,
    vesselYobHeading,
    vesselCategoryHeading,
    vesselDwtHeading,
    vesselScrubberHeading,
    vesselYardHeading,
    vesselDesignHeading,
    vesselImoClassHeading,
    vesselIceClassNotationHeading,
    vesselNitrogenPlantHeading,
    vesselSegsHeading,
    lastOpenLocationHeading,
    lastOpenDateHeading,
    salePriceHeading,
    saleDateHeading,
    laycanYearHeading,
    laycanMonthHeading,
    laycanDecadeHeading,
    privateFieldsHeading,
    rumouredFieldsHeading,
    rumouredHeading,
    actualLoadHeading,
    actualDischargeHeading,
    voyageActivityHeading,
    quotedHeading,
    offersHeading,
    opportunityHeading,
    commissionHeading,
    parcelsHeading,
    channelHeading,
    minRedelivery,
    maxRedelivery,
    createdDateHeading,
    operatorHeading,
];

export default headers;
