import ThirdPartyCompanyInput from '_legacy/modules/shared/columns/inputs/ThirdPartyCompanyInputs';
import { useState } from 'react';
import {
    Button,
    TextField,
    type DialogProps,
    Autocomplete,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputLabel,
    IconButton,
    Checkbox,
    CircularProgress,
    Box,
    Typography,
} from '@mui/material';
import ThirdPartyCompanyApi from '_legacy/api/thirdPartyCompanyApi';
import './CreateCompanyForm.scss';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import { countryCodes } from 'constant/countryCodes';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import MixpanelLogger from '_legacy/diagnostics/logging/mixpanelLogger';

const MIN_COMPANY_NAME_LENGTH = 3;
const NAME_FIELD = 'name';
const ADDRESS_LINE_1_FIELD = 'address.addressLine1';
const ADDRESS_LINE_2_FIELD = 'address.addressLine2';
const POSTAL_CODE_FIELD = 'address.postalCode';
const COUNTRY_CODE_FIELD = 'address.countryCode';
const WEBSITE_URL_FIELD = 'websiteUrl';
const NATURE_OF_BUSINESS_FIELD = 'natureOfBusiness';
const ADDITIONAL_INFORMATION_FIELD = 'additionalInformation';
const CHECKBOX_FIELD = 'isCheckboxChecked';

export interface ICreateCompanyFormProps {
    onCancel: () => void;
    onSubmit: (company: ICompany) => void;
    open: boolean;
    entityId: string;
}

interface ICreateCompanyPayload {
    entityId: string;
    name: string;
    parentId?: string;
    address: {
        addressLine1?: string;
        addressLine2?: string;
        postalCode?: string;
        countryCode: string;
    };
    websiteUrl?: string;
    natureOfBusiness?: string;
    additionalInformation?: string;
}

export interface ICompany {
    value: string;
    name: string;
    partType: string;
}

interface ICreateCompanyFormData {
    name: string;
    parentCompany?: ICompany;
    address: {
        addressLine1?: string;
        addressLine2?: string;
        postalCode?: string;
        countryCode: string;
    };
    websiteUrl?: string;
    natureOfBusiness?: string;
    additionalInformation?: string;
    isCheckboxChecked: boolean;
}

interface IFieldError {
    name: string;
    error: string;
}

interface IFormError {
    detail: string;
    errors: {
        [fieldName: string]: string[];
    };
}

const CreateCompanyForm: React.FC<ICreateCompanyFormProps> = ({
    onCancel,
    onSubmit,
    open,
    entityId,
}) => {
    const [formData, setFormData] = useState<ICreateCompanyFormData>({
        name: '',
        address: {
            countryCode: '',
        },
        isCheckboxChecked: false,
    });

    const [fieldErrors, setFieldErrors] = useState<IFieldError[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<string[]>([]);

    const handleSubmit = async (event: React.FormEvent<HTMLDivElement>) => {
        event.preventDefault();
        setFieldErrors([]);
        setFormErrors([]);
        if (!validateFormData()) {
            return;
        }
        const payload: ICreateCompanyPayload = getPayload(entityId, formData);
        try {
            setLoading(true);
            var result = await ThirdPartyCompanyApi.createCustomCompany(
                payload
            );
            processResponse(result);
        } catch (e: any) {
            if (e?.message && typeof e.message === 'string') {
                setFormErrors([...formErrors, e.message]);
            }
        } finally {
            setLoading(false);
        }
    };

    const processResponse = (result: any) => {
        if (result.error) {
            var formError: IFormError = JSON.parse(result.error);
            if (formError.detail.includes('RequestValidationException')) {
                setFormErrors(Object.values(formError.errors).flat());
            }
        } else if (result.company) {
            onSubmit(result.company);
            MixpanelLogger.trackCompanyFormCreateClick(result.company.name);
        }
    };

    const onClickCancel = (cancelSource: string) => {
        MixpanelLogger.trackCompanyFormCancelClick(formData.name, cancelSource);
        onCancel();
    };

    const validateFormData = (): boolean => {
        const errors: IFieldError[] = [];
        if (!formData.name) {
            errors.push({ name: NAME_FIELD, error: 'Required' });
        } else if (formData.name.length < MIN_COMPANY_NAME_LENGTH) {
            errors.push({
                name: NAME_FIELD,
                error: 'Should have 3-200 characters',
            });
        }
        if (formData.websiteUrl && !isUrl(formData.websiteUrl)) {
            errors.push({
                name: WEBSITE_URL_FIELD,
                error: 'Should be a valid URL',
            });
        }
        if (!formData.isCheckboxChecked) {
            errors.push({ name: CHECKBOX_FIELD, error: '' });
        }
        if (errors.length > 0) {
            setFieldErrors(errors);
            return false;
        }
        return true;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value: fieldValue } = e.target;
        setFieldErrors(fieldErrors.filter((fe) => fe.name !== name));
        const value = fieldValue === '' ? undefined : fieldValue;
        if (name.includes('address')) {
            const addressFieldName = name.split('.')[1];
            setFormData({
                ...formData,
                address: { ...formData.address, [addressFieldName]: value },
            });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleCompanyChange = (company: ICompany) => {
        setFormData({ ...formData, parentCompany: company });
    };

    const handleCountryCodeChange = (event: any, newValue: string | null) => {
        setFieldErrors(
            fieldErrors.filter((fe) => fe.name !== COUNTRY_CODE_FIELD)
        );
        setFormData({
            ...formData,
            address: { ...formData.address, countryCode: newValue ?? '' },
        });
    };

    const handleIsCheckedChange = (event: any, checked: boolean) => {
        setFieldErrors(fieldErrors.filter((fe) => fe.name !== CHECKBOX_FIELD));
        setFormData({
            ...formData,
            isCheckboxChecked: checked,
        });
    };

    const handleClose: DialogProps['onClose'] = (_event, reason) => {
        if (reason && reason === 'backdropClick') return;
    };

    return (
        <Dialog
            open={open}
            className="formContainer light-background"
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
                style: {
                    minHeight: 500,
                },
            }}
        >
            <DialogTitle className="formHeader">
                <b>Create a new company</b>
            </DialogTitle>
            {formErrors.length > 0 && (
                <Box className="formErrorContainer">
                    <ErrorOutlineOutlinedIcon />
                    <Box className="formError">
                        {formErrors.map((error) => (
                            <Typography component="p">{error}</Typography>
                        ))}
                    </Box>
                </Box>
            )}
            <IconButton
                onClick={() => onClickCancel('X')}
                className="closeButton"
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers className="formContent">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <InputLabel className="required">Name</InputLabel>
                        <TextField
                            inputProps={{
                                maxLength: 200,
                            }}
                            variant="outlined"
                            name={NAME_FIELD}
                            value={formData.name}
                            onChange={handleChange}
                            size="small"
                            fullWidth
                            error={fieldErrors.some(
                                (x) => x.name === NAME_FIELD
                            )}
                            helperText={
                                fieldErrors.find((x) => x.name === NAME_FIELD)
                                    ?.error
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel>Parent company</InputLabel>
                        <ThirdPartyCompanyInput
                            value={formData.parentCompany}
                            onTab={() => {}}
                            onTabBack={() => {}}
                            onEnter={() => {}}
                            onCompanySelected={handleCompanyChange}
                            shouldSelectItemOnTab={false}
                            downshiftContentClassname="parentCompanyDropdown"
                            onInputCleared={() => {}}
                            fullWidth
                            placeholder=""
                            className="parentCompanyInput"
                            searchFunc={ThirdPartyCompanyApi.searchParents}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel>Address line 1</InputLabel>
                        <TextField
                            variant="outlined"
                            name={ADDRESS_LINE_1_FIELD}
                            value={formData.address.addressLine1}
                            onChange={handleChange}
                            size="small"
                            fullWidth
                            helperText="Street address, P.O. box"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel>Address line 2</InputLabel>
                        <TextField
                            variant="outlined"
                            name={ADDRESS_LINE_2_FIELD}
                            value={formData.address.addressLine2}
                            onChange={handleChange}
                            size="small"
                            fullWidth
                            helperText="Apartment, suite, unit, building, floor, etc."
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel>Post code (Zip)</InputLabel>
                        <TextField
                            variant="outlined"
                            name={POSTAL_CODE_FIELD}
                            value={formData.address.postalCode}
                            onChange={handleChange}
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel>Country</InputLabel>
                        <Autocomplete
                            disablePortal
                            fullWidth
                            openOnFocus
                            options={countryCodes}
                            value={formData.address.countryCode}
                            onChange={handleCountryCodeChange}
                            ListboxProps={{ className: 'countryDropdown' }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    name={COUNTRY_CODE_FIELD}
                                    size="small"
                                    fullWidth
                                    helperText={
                                        fieldErrors.find(
                                            (x) => x.name === COUNTRY_CODE_FIELD
                                        )?.error
                                    }
                                    error={fieldErrors.some(
                                        (x) => x.name === COUNTRY_CODE_FIELD
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel>Company website</InputLabel>
                        <TextField
                            variant="outlined"
                            name={WEBSITE_URL_FIELD}
                            value={formData.websiteUrl}
                            onChange={handleChange}
                            size="small"
                            fullWidth
                            error={fieldErrors.some(
                                (x) => x.name === WEBSITE_URL_FIELD
                            )}
                            helperText={
                                fieldErrors.find(
                                    (x) => x.name === WEBSITE_URL_FIELD
                                )?.error
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel>Nature of business</InputLabel>
                        <TextField
                            variant="outlined"
                            name={NATURE_OF_BUSINESS_FIELD}
                            value={formData.natureOfBusiness}
                            onChange={handleChange}
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Additional information</InputLabel>
                        <TextField
                            variant="outlined"
                            fullWidth
                            name={ADDITIONAL_INFORMATION_FIELD}
                            value={formData.additionalInformation}
                            onChange={handleChange}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            disableTypography
                            value={formData.isCheckboxChecked}
                            onChange={handleIsCheckedChange}
                            control={
                                <Checkbox
                                    disableRipple
                                    className="formCheckboxButton"
                                />
                            }
                            sx={{
                                color: fieldErrors.some(
                                    (x) => x.name === CHECKBOX_FIELD
                                )
                                    ? 'red'
                                    : 'black',
                            }}
                            label="I confirm that this company will be created ONLY for capturing market data and does not need to go through KYC client check."
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => onClickCancel('Cancel')}
                    variant="outlined"
                    className="formCancelButton"
                    disabled={loading}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    className="formSubmitButton"
                    disabled={loading}
                    startIcon={
                        loading && (
                            <CircularProgress className="loader" size={20} />
                        )
                    }
                >
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const isUrl = (string) => {
    try {
        return Boolean(new URL(string));
    } catch (e) {
        return false;
    }
};

const getPayload = (
    entityId: string,
    formData: ICreateCompanyFormData
): ICreateCompanyPayload => {
    return {
        entityId: entityId,
        name: formData.name,
        parentId: formData.parentCompany?.value,
        address: {
            addressLine1: formData.address.addressLine1,
            addressLine2: formData.address.addressLine2,
            postalCode: formData.address.postalCode,
            countryCode: formData.address.countryCode,
        },
        websiteUrl: formData.websiteUrl,
        natureOfBusiness: formData.natureOfBusiness,
        additionalInformation: formData.additionalInformation,
    };
};

export default CreateCompanyForm;
