import { gql } from '@apollo/client';
import cloneDeep from 'lodash/cloneDeep';
import cloneDeepWith from 'lodash/cloneDeepWith';
import { FIXTURE_FRAGMENT } from '../../fragments/fixtureFragment';
import {
    updateFreightPortBasisIfRequired,
    updateCargoIfRequired,
    updateChannel,
    cleanRatesIfRequired,
    clearDeliveryTermIfRequried,
} from '../../../services/QueryPostProcessingService';
import {
    hasFirstZoneChanged,
    getAvailableZone,
    getAvailableArea,
    getAvailableAreaGeared,
} from '../../../models/Location';
import { vesselCategoriesByFleetTypeIds } from '_legacy/modules/columns/vessel/models/VesselCategory';
import {
    CARGO_QTY_FRAGMENT_NAME,
    CARGO_QTY_FRAGMENT,
    CARGO_QTY_TYPE_NAME,
} from '../../fragments/cargoQuantityPartFragment';
import {
    COMPANY_FRAGMENT,
    COMPANY_FRAGMENT_NAME,
    COMPANY_TYPE_NAME,
} from '../../fragments/companyFragment';
import {
    VESSEL_FRAGMENT,
    VESSEL_FRAGMENT_NAME,
    VESSEL_TYPE_NAME,
} from '../../fragments/vesselFragment';
import {
    LAYCAN_FRAGMENT,
    LAYCAN_FRAGMENT_NAME,
    LAYCAN_TYPE_NAME,
} from '../../fragments/laycanFragment';
import {
    LOCATION_PART_FRAGMENT_NAME,
    LOCATION_PART_FRAGMENT,
    LOCATION_PART_TYPE_NAME,
} from '../../fragments/locationPartFragment';
import {
    CARGO_PART_FRAGMENT_NAME,
    CARGO_PART_FRAGMENT,
    CARGO_PART_TYPE_NAME,
} from '../../fragments/cargoPartFragment';
import {
    FREIGHT_PORT_BASIS_TYPE_NAME,
    FREIGHT_PORT_BASIS_FRAGMENT_NAME,
    FREIGHT_PORT_BASIS_FRAGMENT,
} from '../../fragments/freightPortBasisFragment';
import {
    THIRD_PARTY_COMPANY_PART_FRAGMENT,
    THIRD_PARTY_COMPANY_PART_FRAGMENT_NAME,
    THIRD_PARTY_COMPANY_PART_TYPE_NAME,
} from '../../fragments/thirdPartyCompanyPartFragment';
import {
    CLARKSONS_USER_FRAGMENT,
    CLARKSONS_USER_FRAGMENT_NAME,
    CLARKSONS_USER_TYPE_NAME,
} from '../../fragments/clarksonsUserFragment';
import {
    DURATION_PART_FRAGMENT_NAME,
    DURATION_PART_FRAGMENT,
    DURATION_PART_TYPE_NAME,
} from '../../fragments/durationPartFragment';
import { getVesselPostProcessingService } from '_legacy/modules/columns/vessel';
import {
    COMMISSION_FRAGMENT,
    COMMISSION_FRAGMENT_NAME,
    COMMISSION_TYPE_NAME,
} from '_legacy/api/fragments/commissionFragment';
import {
    RATES_FRAGMENT,
    RATES_FRAGMENT_NAME,
    RATES_TYPE_NAME,
} from '_legacy/api/fragments/ratesFragment';

export const FIXTURE_RECENT_CHANGES_FOR_DATASET_QUERY = gql`
    query FixtureChanges($groupId: ID!, $numberOfYears: Int!) {
        sharedFixturesRecentlyChanged(
            groupId: $groupId
            numberOfYears: $numberOfYears
        ) {
            ...FixtureFragment
        }

        groupOnlyFixtures(groupId: $groupId, numberOfYears: $numberOfYears) {
            ...FixtureFragment
        }

        individualFixtures(groupId: $groupId, numberOfYears: $numberOfYears) {
            ...FixtureFragment
        }

        fixturesWithPrivateComments(
            groupId: $groupId
            numberOfYears: $numberOfYears
        ) {
            ...FixtureFragment
        }
    }

    ${FIXTURE_FRAGMENT}
`;

export const INNER_FIXTURE_MUTATION = `
createFixture(groupId: $groupId, groupOnly: $groupOnly, privacy: $privacy, isIndividual: $isIndividual, type: $type) {
  ...FixtureFragment
}
`;

export const CREATE_FIXTURE_MUTATION = gql`
  mutation CreateFixture($groupId: ID!, $groupOnly: Boolean!, $privacy: Boolean!, $isIndividual: Boolean!, $type: String!) {
    ${INNER_FIXTURE_MUTATION}
  }
  
  ${FIXTURE_FRAGMENT}
`;

export const SHARE_FIXTURE_MUTATION = gql`
    mutation ShareFixture($id: ID!) {
        shareFixture(id: $id) {
            id
            groupOnly
            isIndividual
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const SHARE_FIXTURE_TO_GROUP_MUTATION = gql`
    mutation ShareFixtureToGroup($id: ID!) {
        shareFixtureToGroup(id: $id) {
            id
            groupOnly
            isIndividual
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const DELETE_FIXTURE_MUTATION = gql`
    mutation DeleteFixture($groupId: ID!, $id: ID!) {
        deleteFixture(groupId: $groupId, id: $id) {
            id
            state
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const MAKE_FIXTURE_PUBLIC_MUTATION = gql`
    mutation MakeFixturePublic($groupId: ID!, $id: ID!) {
        makeFixturePublic(groupId: $groupId, id: $id) {
            id
            privacy
            privateFields
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const MAKE_FIXTURE_PRIVATE_MUTATION = gql`
    mutation MakeFixturePrivate($groupId: ID!, $id: ID!) {
        makeFixturePrivate(groupId: $groupId, id: $id) {
            id
            privacy
            privateFields
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const UPDATE_FIXTURE_PRIVATE_FIELDS_MUTATION = gql`
    mutation UpdateFixturePrivateFields(
        $groupId: ID!
        $id: ID!
        $fields: [String]!
    ) {
        updateFixturePrivateFields(
            groupId: $groupId
            id: $id
            fields: $fields
        ) {
            id
            privacy
            privateFields
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const MAKE_FIXTURE_UNRUMOURED_MUTATION = gql`
    mutation MakeFixtureUnRumoured($groupId: ID!, $id: ID!) {
        makeFixtureUnRumoured(groupId: $groupId, id: $id) {
            id
            rumoured
            rumouredFields
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const MAKE_FIXTURE_RUMOURED_MUTATION = gql`
    mutation MakeFixtureRumoured($groupId: ID!, $id: ID!) {
        makeFixtureRumoured(groupId: $groupId, id: $id) {
            id
            rumoured
            rumouredFields
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const UPDATE_FIXTURE_RUMOURED_FIELDS_MUTATION = gql`
    mutation UpdateFixtureRumouredFields(
        $groupId: ID!
        $id: ID!
        $fields: [String]!
    ) {
        updateFixtureRumouredFields(
            groupId: $groupId
            id: $id
            fields: $fields
        ) {
            id
            rumoured
            rumouredFields
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const REINSTATE_FIXTURE_MUTATION = gql`
    mutation ReinstateFixture($groupId: ID!, $id: ID!) {
        reinstateFixture(groupId: $groupId, id: $id) {
            id
            state
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const CLONE_FIXTURE_WITH_POS_INFO_MUTATION = gql`
    mutation CloneFixtureWithPosInfo($id: ID!, $groupId: ID!) {
        cloneFixtureWithPosInfo(id: $id, groupId: $groupId) {
            ...FixtureFragment
        }
    }

    ${FIXTURE_FRAGMENT}
`;

export const CLONE_FIXTURE_WITH_CARGO_INFO_MUTATION = gql`
    mutation CloneFixtureWithCargoInfo($id: ID!, $groupId: ID!) {
        cloneFixtureWithCargoInfo(id: $id, groupId: $groupId) {
            ...FixtureFragment
        }
    }

    ${FIXTURE_FRAGMENT}
`;

export const GET_MAINTAINANCE_MESSAGE_QUERY = gql`
    query GetAnalyticsMaintainanceMessage($reportName: String!) {
        getAnalyticsMaintainanceMessage(reportName: $reportName)
    }
`;

const addTypeName = (obj, type) => {
    if (Array.isArray(obj)) {
        for (var i = 0; i < obj.length; i++) {
            addTypeName(obj[i], type);
        }
    } else {
        obj['__typename'] = type;
    }
};

const omitDeep = (collection, excludeKeys) => {
    function omitFn(value) {
        if (value && typeof value === 'object') {
            excludeKeys.forEach((key) => {
                delete value[key];
            });
        }
    }
    return cloneDeepWith(collection, omitFn);
};

export const createFixtureFieldMutation = (
    data,
    id,
    field,
    value,
    username,
    userId,
    groupId
) => {
    let mutationStructure = field;
    let fragment = '';
    let fragmentName = '';
    let type = '';
    let variableName = field;
    let customUpdate = null;
    let filtersToBeRefreshed = null;
    let sanitizeVariable = null;
    let mutationValue = cloneDeep(value);
    let gridValue = cloneDeep(value);

    const gridType = 'fixture';

    let variables = {
        groupId: groupId,
        fixture: {
            id: id,
        },
    };

    //TODO: use valueParser column definition instead
    switch (field) {
        case 'laycan':
            mutationStructure = `${field} { ...${LAYCAN_FRAGMENT_NAME} } minRedelivery maxRedelivery`;
            fragment = LAYCAN_FRAGMENT;
            variableName = 'laycanShorthand';
            type = LAYCAN_TYPE_NAME;
            if (mutationValue) {
                mutationValue = mutationValue.shorthand;
            }
            break;

        case 'charterer':
        case 'owner':
            fragmentName = COMPANY_FRAGMENT_NAME;
            fragment = COMPANY_FRAGMENT;
            type = COMPANY_TYPE_NAME;
            break;

        case 'brokers':
            fragmentName = COMPANY_FRAGMENT_NAME;
            fragment = COMPANY_FRAGMENT;
            type = COMPANY_TYPE_NAME;
            filtersToBeRefreshed = ['channel'];

            customUpdate = (data, oldValue, newValue) => {
                updateChannel(data, variables, newValue, gridType);
            };

            break;

        case 'cargoQuantityParts':
            fragmentName = CARGO_QTY_FRAGMENT_NAME;
            fragment = CARGO_QTY_FRAGMENT;
            type = CARGO_QTY_TYPE_NAME;
            break;

        case 'freightPortBasis':
            fragmentName = FREIGHT_PORT_BASIS_FRAGMENT_NAME;
            fragment = FREIGHT_PORT_BASIS_FRAGMENT;
            type = FREIGHT_PORT_BASIS_TYPE_NAME;

            sanitizeVariable = (mutationValue) => {
                let freightPortBasis = {};

                freightPortBasis.shouldBeUpdatedAutomatically =
                    mutationValue.shouldBeUpdatedAutomatically;
                freightPortBasis.parts = mutationValue.parts.map((x) => {
                    return {
                        loadCount: +x.loadCount,
                        dischargeCount: +x.dischargeCount,
                    };
                });

                return freightPortBasis;
            };
            break;

        case 'cargoParts':
            fragmentName = CARGO_PART_FRAGMENT_NAME;
            fragment = CARGO_PART_FRAGMENT;
            type = CARGO_PART_TYPE_NAME;
            break;

        case 'commission':
            fragmentName = COMMISSION_FRAGMENT_NAME;
            fragment = COMMISSION_FRAGMENT;
            type = COMMISSION_TYPE_NAME;
            break;

        case 'thirdPartyCharterer':
        case 'thirdPartyOwner':
        case 'buyer':
        case 'operator':
            fragmentName = THIRD_PARTY_COMPANY_PART_FRAGMENT_NAME;
            fragment = THIRD_PARTY_COMPANY_PART_FRAGMENT;
            type = THIRD_PARTY_COMPANY_PART_TYPE_NAME;
            break;

        case 'thirdPartyBrokers':
            fragmentName = THIRD_PARTY_COMPANY_PART_FRAGMENT_NAME;
            fragment = THIRD_PARTY_COMPANY_PART_FRAGMENT;
            type = THIRD_PARTY_COMPANY_PART_TYPE_NAME;
            filtersToBeRefreshed = ['channel'];

            customUpdate = (data, oldValue, newValue) => {
                updateChannel(data, variables, newValue, gridType);
            };

            break;

        case 'clarksonsBrokers':
            fragmentName = CLARKSONS_USER_FRAGMENT_NAME;
            fragment = CLARKSONS_USER_FRAGMENT;
            type = CLARKSONS_USER_TYPE_NAME;
            break;

        case 'rates':
            fragmentName = RATES_FRAGMENT_NAME;
            fragment = RATES_FRAGMENT;
            type = RATES_TYPE_NAME;
            break;

        case 'vessel.design':
        case 'vessel.yard':
        case 'vessel.dwt':
        case 'vessel.yob':
        case 'vessel.scrubber':
        case 'vessel.iceClassNotation':
        case 'vessel.imoClass':
            fragmentName = VESSEL_FRAGMENT_NAME;
            fragment = VESSEL_FRAGMENT;
            type = VESSEL_TYPE_NAME;

            const fields = field.split('.');
            let vessel = {};
            vessel[fields[1]] = mutationValue;

            field = fields[0];
            mutationStructure = fields[0];
            variableName = fields[0];
            mutationValue = vessel;
            gridValue = vessel;
            break;

        case 'vessel':
            fragmentName = VESSEL_FRAGMENT_NAME;
            fragment = VESSEL_FRAGMENT;
            type = VESSEL_TYPE_NAME;
            filtersToBeRefreshed = ['vesselCategory'];

            customUpdate = (data, oldValue, newValue) => {
                const vesselCategory =
                    newValue && newValue.dwt
                        ? vesselCategoriesByFleetTypeIds(
                              data.datasetId,
                              newValue.fleetTypeIds
                          )
                        : null;
                data['vesselCategory'] = vesselCategory;
                variables.fixture['vesselCategory'] = vesselCategory;

                const vesselService = getVesselPostProcessingService(
                    data.datasetId
                );

                if (vesselService) {
                    vesselService.updateOwnerIfRequired(
                        newValue,
                        data,
                        variables,
                        'fixture'
                    );
                }
            };

            sanitizeVariable = (mutationValue) => {
                delete mutationValue['bestOperator'];
                delete mutationValue['gainCompany'];
                delete mutationValue['shouldUpdateOwner'];
                delete mutationValue['fleetTypeIds'];
                return mutationValue;
            };
            break;
        case 'salePrice':
            if (mutationValue) {
                mutationValue = parseFloat(mutationValue);
            }
            break;
        case 'loadLocationParts':
            mutationStructure = `${field} { ...${LOCATION_PART_FRAGMENT_NAME} } loadZone areas`;
            fragment = LOCATION_PART_FRAGMENT;
            type = LOCATION_PART_TYPE_NAME;
            filtersToBeRefreshed = [
                'freightPortBasis',
                'loadZone',
                'areas',
                'areasGeared',
                'direction',
            ];

            customUpdate = (data, oldValue, newValue) => {
                updateFreightPortBasisIfRequired(
                    data,
                    variables,
                    mutationValue,
                    'dischargeLocationParts',
                    gridType
                );

                if (hasFirstZoneChanged(data.loadZone, newValue)) {
                    const zone = getAvailableZone(newValue);
                    const area = getAvailableArea(newValue);
                    const areaGeared = getAvailableAreaGeared(newValue);
                    data['loadZone'] = zone;
                    data['areas'] = area;
                    data['areasGeared'] = areaGeared;
                    variables.fixture['loadZone'] = zone;
                    variables.fixture['areas'] = area;
                    variables.fixture['areasGeared'] = areaGeared;
                }
            };

            // Don't send zone and area in the mutation to the graph.
            // They're only present for the purpose of setting area when changing load zone.
            sanitizeVariable = (mutationValue) => {
                if (mutationValue === null) {
                    return mutationValue;
                }

                let values = [];

                for (let i = 0; i < mutationValue.length; i++) {
                    values.push({
                        name: mutationValue[i].name,
                        partType: mutationValue[i].partType,
                        value: mutationValue[i].value,
                        isSTS: mutationValue[i].isSTS,
                    });
                }
                return values;
            };

            break;

        case 'dischargeLocationParts':
            mutationStructure = `${field} { ...${LOCATION_PART_FRAGMENT_NAME} } dischargeZone`;
            fragment = LOCATION_PART_FRAGMENT;
            type = LOCATION_PART_TYPE_NAME;
            filtersToBeRefreshed = [
                'freightPortBasis',
                'dischargeZone',
                'direction',
            ];

            customUpdate = (data, oldValue, newValue) => {
                updateFreightPortBasisIfRequired(
                    data,
                    variables,
                    mutationValue,
                    'loadLocationParts',
                    gridType
                );

                if (hasFirstZoneChanged(data.dischargeZone, newValue)) {
                    const zone = getAvailableZone(newValue);
                    data['dischargeZone'] = zone;
                    variables.fixture['dischargeZone'] = zone;
                }
            };

            // Don't send zone and area in the mutation to the graph.
            // They're only present for the purpose of setting area when changing load zone.
            sanitizeVariable = (mutationValue) => {
                if (mutationValue === null) {
                    return mutationValue;
                }
                let values = [];
                for (let i = 0; i < mutationValue.length; i++) {
                    values.push({
                        name: mutationValue[i].name,
                        partType: mutationValue[i].partType,
                        value: mutationValue[i].value,
                        isSTS: mutationValue[i].isSTS,
                    });
                }
                return values;
            };

            break;

        case 'lastOpenLocationParts':
        case 'actualDischarge':
        case 'actualLoad':
        case 'deliveryLocationParts':
        case 'redeliveryLocationParts':
            fragmentName = LOCATION_PART_FRAGMENT_NAME;
            fragment = LOCATION_PART_FRAGMENT;
            type = LOCATION_PART_TYPE_NAME;
            sanitizeVariable = (mutationValue) => {
                if (mutationValue === null) {
                    return mutationValue;
                }

                let values = [];
                for (let i = 0; i < mutationValue.length; i++) {
                    values.push({
                        name: mutationValue[i].name,
                        partType: mutationValue[i].partType,
                        value: mutationValue[i].value,
                        isSTS: mutationValue[i].isSTS,
                    });
                }
                return values;
            };
            break;

        case 'dischargeZone':
        case 'loadZone':
            filtersToBeRefreshed = ['direction'];
            break;

        case 'type':
            cleanRatesIfRequired(data, variables, value, gridType);
            updateCargoIfRequired(data, variables, value, gridType);
            clearDeliveryTermIfRequried(data, variables, value, gridType);
            break;

        case 'durationParts':
            mutationStructure = `${field} { ...${DURATION_PART_FRAGMENT_NAME} } minRedelivery maxRedelivery`;
            fragment = DURATION_PART_FRAGMENT;
            type = DURATION_PART_TYPE_NAME;
            break;

        default:
            break;
    }

    if (fragmentName) {
        mutationStructure = `${field} { ...${fragmentName} }`;
    }

    if (type && gridValue) {
        addTypeName(gridValue, type);
    }

    const innerMutation = `
  updateFixture(groupId: $groupId, fixture: $fixture) {
    id
    ${mutationStructure}
    lastUpdatedDateTimeByUser
    lastUpdatedByUser {
      username
      fullName
    }
  }`;

    const mutation = gql`
    mutation set_fixture_${field}($groupId: ID!, $fixture: FixtureInput!) {
      ${innerMutation}
    }

    ${fragment}
  `;

    if (mutationValue && sanitizeVariable) {
        variables.fixture[variableName] = sanitizeVariable(mutationValue);
    } else {
        variables.fixture[variableName] = mutationValue;
    }

    //remove any __typename properties that may be in the value else GraphQL will reject the mutation as having
    //an extra field in the input properties
    variables = omitDeep(variables, ['__typename']);

    return {
        variables,
        mutation,
        innerMutation,
        mutationValue,
        gridValue,
        customUpdate,
        filtersToBeRefreshed,
    };
};
