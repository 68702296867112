import { gql } from '@apollo/client';
import { SAVED_LAYOUT_FRAGMENT, SavedLayoutFragment } from './fragments';
import graphQlClient from './graphQlClient';
import { DATASET_SPECIALISED } from '_legacy/models/Datasets';

export module LayoutsApi {
    export interface LayoutCreateInput {
        datasetId: number;
        name: string;
        fixtures?: string;
        orders?: string;
        commonSettings?: {
            directionLogic: string;
            quantityFormat: string;
            defaultType: string;
        };
    }

    const CREATE_USER_LAYOUT_MUTATION = gql`
        ${SAVED_LAYOUT_FRAGMENT}

        mutation CreateUserLayout(
            $datasetId: Int!
            $name: String!
            $fixtures: String
            $orders: String
            $commonSettings: CommonSettingsInput
        ) {
            createUserLayout(
                datasetId: $datasetId
                name: $name
                fixtures: $fixtures
                orders: $orders
                commonSettings: $commonSettings
            ) {
                ...SavedLayoutFragment
            }
        }
    `;

    export async function createUserLayout({
        datasetId,
        name,
        fixtures,
        orders,
        commonSettings,
    }: LayoutCreateInput): Promise<SavedLayoutFragment> {
        const result = await graphQlClient().mutate<{
            createUserLayout: SavedLayoutFragment;
        }>({
            mutation: CREATE_USER_LAYOUT_MUTATION,
            variables: {
                datasetId,
                name,
                fixtures,
                orders,
                commonSettings,
            },
        });

        if (!result.data) {
            throw new Error('Failed to create layout');
        }

        return result.data.createUserLayout;
    }

    export interface LayoutUpdateInput {
        id: string;
        name: string;
        fixtures?: string;
        orders?: string;
        commonSettings?: {
            directionLogic: string;
            quantityFormat: string;
            defaultType: string;
        };
    }

    const UPDATE_USER_LAYOUT_MUTATION = gql`
        ${SAVED_LAYOUT_FRAGMENT}

        mutation UpdateUserLayout(
            $id: ID!
            $name: String!
            $fixtures: String
            $orders: String
            $commonSettings: CommonSettingsInput
        ) {
            updateUserLayout(
                id: $id
                name: $name
                fixtures: $fixtures
                orders: $orders
                commonSettings: $commonSettings
            ) {
                ...SavedLayoutFragment
            }
        }
    `;

    export async function updateUserLayout({
        id,
        name,
        fixtures,
        orders,
        commonSettings,
    }: LayoutUpdateInput): Promise<SavedLayoutFragment> {
        const result = await graphQlClient().mutate<{
            updateUserLayout: SavedLayoutFragment;
        }>({
            mutation: UPDATE_USER_LAYOUT_MUTATION,
            variables: {
                id,
                name,
                fixtures,
                orders,
                commonSettings,
            },
        });

        if (!result.data) {
            throw new Error('Failed to update layout');
        }

        return result.data.updateUserLayout;
    }

    const RENAME_USER_LAYOUT_MUTATION = gql`
        ${SAVED_LAYOUT_FRAGMENT}

        mutation RenameUserLayout($id: ID!, $name: String!) {
            renameUserLayout(id: $id, name: $name) {
                ...SavedLayoutFragment
            }
        }
    `;

    export async function renameUserLayout(
        id: string,
        name: string
    ): Promise<SavedLayoutFragment> {
        const result = await graphQlClient().mutate<{
            renameUserLayout: SavedLayoutFragment;
        }>({
            mutation: RENAME_USER_LAYOUT_MUTATION,
            variables: {
                id,
                name,
            },
        });

        if (!result.data) {
            throw new Error('Failed to rename layout');
        }

        return result.data.renameUserLayout;
    }

    const SET_USER_LAYOUT_AS_PREFERRED_MUTATION = gql`
        ${SAVED_LAYOUT_FRAGMENT}

        mutation SetUserLayoutAsPreferred($id: ID!) {
            setUserLayoutAsPreferred(id: $id) {
                ...SavedLayoutFragment
            }
        }
    `;

    export async function setUserLayoutAsPreferred(
        id: string
    ): Promise<SavedLayoutFragment> {
        const result = await graphQlClient().mutate<{
            setUserLayoutAsPreferred: SavedLayoutFragment;
        }>({
            mutation: SET_USER_LAYOUT_AS_PREFERRED_MUTATION,
            variables: {
                id,
            },
        });

        if (!result.data) {
            throw new Error('Failed to update layout');
        }

        return result.data.setUserLayoutAsPreferred;
    }

    const DELETE_USER_LAYOUT_MUTATION = gql`
        ${SAVED_LAYOUT_FRAGMENT}

        mutation DeleteUserLayout($id: ID!) {
            deleteUserLayout(id: $id) {
                ...SavedLayoutFragment
            }
        }
    `;

    export async function deleteUserLayout(
        id: string
    ): Promise<SavedLayoutFragment> {
        const result = await graphQlClient().mutate<{
            deleteUserLayout: SavedLayoutFragment;
        }>({
            mutation: DELETE_USER_LAYOUT_MUTATION,
            variables: {
                id,
            },
        });

        if (!result.data) {
            throw new Error('Failed to delete layout');
        }

        return result.data.deleteUserLayout;
    }

    const CREATE_SHARED_LAYOUT_MUTATION = gql`
        ${SAVED_LAYOUT_FRAGMENT}

        mutation CreateSharedLayout(
            $datasetId: Int!
            $name: String!
            $fixtures: String
            $orders: String
            $commonSettings: CommonSettingsInput
        ) {
            createSharedLayout(
                datasetId: $datasetId
                name: $name
                fixtures: $fixtures
                orders: $orders
                commonSettings: $commonSettings
            ) {
                ...SavedLayoutFragment
            }
        }
    `;

    export async function createSharedLayout({
        datasetId,
        name,
        fixtures,
        orders,
        commonSettings,
    }: LayoutCreateInput): Promise<SavedLayoutFragment> {
        const result = await graphQlClient().mutate<{
            createSharedLayout: SavedLayoutFragment;
        }>({
            mutation: CREATE_SHARED_LAYOUT_MUTATION,
            variables: {
                datasetId,
                name,
                fixtures,
                orders,
                commonSettings,
            },
        });

        if (!result.data) {
            throw new Error('Failed to create layout');
        }

        return result.data.createSharedLayout;
    }

    export interface LayoutUpdateInput {
        id: string;
        name: string;
        fixtures?: string;
        orders?: string;
        commonSettings?: {
            directionLogic: string;
            quantityFormat: string;
            defaultType: string;
        };
    }

    const UPDATE_SHARED_LAYOUT_MUTATION = gql`
        ${SAVED_LAYOUT_FRAGMENT}

        mutation UpdateSharedLayout(
            $id: ID!
            $name: String!
            $fixtures: String
            $orders: String
            $commonSettings: CommonSettingsInput
        ) {
            updateSharedLayout(
                id: $id
                name: $name
                fixtures: $fixtures
                orders: $orders
                commonSettings: $commonSettings
            ) {
                ...SavedLayoutFragment
            }
        }
    `;

    export async function updateSharedLayout({
        id,
        name,
        fixtures,
        orders,
        commonSettings,
    }: LayoutUpdateInput): Promise<SavedLayoutFragment> {
        const result = await graphQlClient().mutate<{
            updateSharedLayout: SavedLayoutFragment;
        }>({
            mutation: UPDATE_SHARED_LAYOUT_MUTATION,
            variables: {
                id,
                name,
                fixtures,
                orders,
                commonSettings,
            },
        });

        if (!result.data) {
            throw new Error('Failed to update layout');
        }

        return result.data.updateSharedLayout;
    }

    const RENAME_SHARED_LAYOUT_MUTATION = gql`
        ${SAVED_LAYOUT_FRAGMENT}

        mutation RenameSharedLayout($id: ID!, $name: String!) {
            renameSharedLayout(id: $id, name: $name) {
                ...SavedLayoutFragment
            }
        }
    `;

    export async function renameSharedLayout(
        id: string,
        name: string
    ): Promise<SavedLayoutFragment> {
        const result = await graphQlClient().mutate<{
            renameSharedLayout: SavedLayoutFragment;
        }>({
            mutation: RENAME_SHARED_LAYOUT_MUTATION,
            variables: {
                id,
                name,
            },
        });

        if (!result.data) {
            throw new Error('Failed to rename layout');
        }

        return result.data.renameSharedLayout;
    }

    const DELETE_SHARED_LAYOUT_MUTATION = gql`
        ${SAVED_LAYOUT_FRAGMENT}

        mutation DeleteSharedLayout($id: ID!) {
            deleteSharedLayout(id: $id) {
                ...SavedLayoutFragment
            }
        }
    `;

    export async function deleteSharedLayout(
        id: string
    ): Promise<SavedLayoutFragment> {
        const result = await graphQlClient().mutate<{
            deleteSharedLayout: SavedLayoutFragment;
        }>({
            mutation: DELETE_SHARED_LAYOUT_MUTATION,
            variables: {
                id,
            },
        });

        if (!result.data) {
            throw new Error('Failed to delete layout');
        }

        return result.data.deleteSharedLayout;
    }

    export function getDatasetDefaultColumns(
        columnState,
        defaultColIds,
        datasetId
    ) {
        if (!defaultColIds?.length) {
            return columnState;
        }
        const defaultColumns: any[] = [];
        for (const field of defaultColIds) {
            const column = columnState.find(
                (column) => field.localeCompare(column.colId) === 0
            );
            if (column) {
                column.hide = false;
                if (datasetId === DATASET_SPECIALISED) {
                    column.sort = null;
                }
                defaultColumns.push(column);
            }
        }
        for (const column of columnState) {
            if (!defaultColIds.includes(column.colId)) {
                column.hide = true;
                defaultColumns.push(column);
            }
        }
        return defaultColumns;
    }
}
