import {
    DATASET_DRY_CARGO,
    DATASET_LPG_VLGC,
    DATASET_PCG,
} from '../../../models/Datasets';

const NonEmptyVoyDatasets = [DATASET_DRY_CARGO, DATASET_PCG, DATASET_LPG_VLGC];

const TypeFormatter = (params) => {
    const { value, node, datasetId } = params;

    const currentDatasetId =
        node && node.data ? node.data.datasetId : datasetId;
    if (
        value === 'VOY' &&
        node !== null &&
        !node.group &&
        !NonEmptyVoyDatasets.includes(currentDatasetId)
    ) {
        return '';
    }
    return value;
};

export const AllTypeFormatter = (value) => {
    if (!value) {
        return '';
    }

    return value;
};

export default TypeFormatter;
